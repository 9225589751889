import React from "react";
import ReactDOM from "react-dom";
import Graph from "react-graph-vis";

// import "./styles.css";
// need to import the vis network css in order to show tooltip
// import "./network.css";
const orangeColor = "rgba(255,111,27,0.9)";
const yellowColor = "rgba(255,192,0,0.9)";
const greenColor = "rgba(112,173,71,0.9)";
const blueColor = "rgba(68,114,196,0.9)";
const purple = "rgba(180,0,124,0.9)";
const cyn = "rgba(12,172,168,0.9)";

const colorList = {
    "WEST TAILORS": orangeColor,
    "DAVID SMITH": cyn,
    "ABC MOTORS": yellowColor,
    "TIM CARLOS": purple,
    "XJSF PTE LTD": blueColor,
    "SARAH LEE": greenColor
};

let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

let options = {
    layout: {
        // randomSeed: "0.8626905353696983:1620195414084"
    },
    nodes: {
        fixed: {
            x: false,
            y: false
        },
        scaling: {
            customScalingFunction: function(min, max, total, value) {
                if (max === min) {
                    return 0.5;
                } else {
                    var scale = 1 / (max - min);
                    return Math.max(0, (value - min) * scale);
                }
            },
            min: (30 / 16) * rem,
            max: (50 / 16) * rem
        },
        shape: "circle",
        // size: 20,
        borderWidth: 1.5,
        borderWidthSelected: 2,
        font: {
            color: "white",
            size: rem,
            align: "center",
            bold: {
                color: "#bbbdc0",
                // size: 15,
                mod: "bold"
            }
        }
    },
    edges: {
        font: {
            color: "white",
            strokeWidth: 0,
            align: "middle",
            vadjust: -10
        },
        width: 0.01,
        color: {
            color: "#D3D3D3",
            highlight: "#797979",
            hover: "#797979",
            opacity: 1.0
        },
        // scaling: {
        //     customScalingFunction: function(min, max, total, value) {
        //         if (max === min) {
        //             return 0.5;
        //         } else {
        //             var scale = 1 / (max - min);
        //             return Math.max(0, (value - min) * scale);
        //         }
        //     },
        //     min: 1,
        //     max: 200
        // },
        // arrows: {
        //     to: { enabled: true, scaleFactor: 1, type: "arrow" },
        //     middle: { enabled: false, scaleFactor: 1, type: "arrow" },
        //     from: { enabled: false, scaleFactor: 1, type: "arrow" }
        // },
        smooth: false
    },
    physics: {
        barnesHut: {
            gravitationalConstant: -30000,
            centralGravity: 1,
            springLength: (130 / 17.75) * rem,
            springConstraint: 0.24,
            damping: 0.15,
            avoidOverlap: 1
        },
        stabilization: { iterations: 1 }
    },
    interaction: {
        hover: false,
        hoverConnectedEdges: false,
        selectable: false,
        selectConnectedEdges: false,
        zoomView: false,
        dragView: false,
        dragNodes: false
    }
};

const events = {
    select: function(event) {
        var { nodes, edges } = event;
    }
};

class NetworkGraph extends React.Component {
    constructor() {
        super();
        this.state = {
            graph: { nodes: [], edges: [] },
            legend: []
        };
    }
    componentDidMount() {
        if (this.props.data.length === 0) {
            console.log("empty transactions");
            return;
        }
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );

        let nodes = [];
        let edges = [];
        if (this.props.rootNode) {
            let rootNode = this.props.rootNode;
            nodes.push(rootNode);
        }
        this.props.data.map((n, i) => {
            nodes.push({
                id: n[this.props.nodeIDName],
                label: n[this.props.nodeLabelName],
                value: n[this.props.nodeValueName],
                color: n[this.props.nodeColorName]
                // value: 100,
                // x:
                //     t.type === "DR"
                //         ? Math.random() * ((400 / 16) * rem) + (150 / 16) * rem
                //         : Math.random() * ((-400 / 16) * rem) -
                //         (150 / 16) * rem,
            });
            // if (t.type === "DR") edges.push({ from: 0, to: i + 1 });
            //
            // if (t.type === "CR") edges.push({ from: i + 1, to: 0 });
            n[this.props.nodeToName].map(to => {
                edges.push({
                    from: n[this.props.nodeIDName],
                    to: to.id,
                    label: to.label
                });
            });
        });
        if (this.props.noDirection) {
            let newEdges = [];
            edges.map(edge => {
                let existing = false;
                newEdges.map(newEdge => {
                    if (newEdge.from === edge.to && newEdge.to === edge.from) {
                        existing = true;
                    }
                });
                if (!existing) {
                    newEdges.push(edge);
                }
            });
            edges = newEdges;
        }

        let legend = [];
        this.props.data.map(node => {
            let existing = false;
            legend.map(legend => {
                if (
                    legend.color === node[this.props.nodeColorName] &&
                    legend.text === node[this.props.nodeLegendTextName]
                ) {
                    existing = true;
                }
            });
            if (!existing) {
                legend.push({
                    color: node[this.props.nodeColorName],
                    text: node[this.props.nodeLegendTextName]
                });
            }
        });

        this.setState({
            graph: { nodes: nodes, edges: edges },
            legend: legend
        });
    }
    render() {
        if (this.props.data.length === 0) {
            return <div />;
        }
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );

        options.edges.arrows = this.props.arrows;
        options.nodes.font.size = rem;
        options.layout.randomSeed =
            rem > 17
                ? "0.8626905353696983:1620195414084"
                : "0.19691900194960543:1620195969052";
        return (
            <div>
                <div
                    style={{
                        position: "absolute",
                        color: "white",
                        margin: "1rem",
                        right: 0,
                        bottom: 0
                    }}
                >
                    Legend
                    {this.state.legend.map(legendItem => {
                        return (
                            <div>
                                <div
                                    style={{
                                        display: "inline-flex",
                                        backgroundColor: legendItem.color,
                                        borderRadius: "50%",
                                        width: "0.5rem",
                                        height: "0.5rem",
                                        marginRight: "0.5rem"
                                    }}
                                />
                                <div
                                    style={{
                                        display: "inline-flex",
                                        color: "white"
                                    }}
                                >
                                    {legendItem.text}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Graph
                    graph={this.state.graph}
                    options={{ ...options, height: (500 / 16) * rem + "px" }}
                    events={events}
                    getNetwork={network => {
                        //  if you want access to vis.js network api you can set the state in a parent component using this property
                        console.log(network.getSeed());
                    }}
                />
            </div>
        );
    }
}

export { NetworkGraph };
