import React from "react";

import ArchiveIconSVG from "../icon/Google Materials Icons/content/archive_24px.svg";
import AddCircleOutline from "../icon/Google Materials Icons/content/add_circle_outline_24px.svg";
import HomeIcon from "../icon/Google Materials Icons/action/home_24px.svg";
import DeleteIcon from "../icon/Google Materials Icons/action/delete_24px.svg";
import CalendarIcon from "../icon/Google Materials Icons/action/date_range_24px.svg";
import WorkOutlineIcon from "../icon/Google Materials Icons/action/work_outline_24px.svg";
import MoreHoriIcon from "../icon/Google Materials Icons/navigation/more_horiz_24px.svg";
import VersionCommitActive from "../icon/Customize Icon/commit_active.svg";
import VersionCommitInactive from "../icon/Customize Icon/commit_inactive.svg";
import VersionBranchActive from "../icon/Customize Icon/branch_active.svg";
import VersionBranchInactive from "../icon/Customize Icon/branch_inactive.svg";
import ListNumber from "../icon/Google Materials Icons/editor/format_list_numbered_24px.svg";
import BarChart from "../icon/Google Materials Icons/editor/bar_chart_24px.svg";
import File from "../icon/Google Materials Icons/action/description-24px.svg";
import Info from "../icon/Google Materials Icons/action/info-24px.svg";
import Cancel from "../icon/Google Materials Icons/navigation/cancel_24px.svg";
import Speaker from "../icon/Google Materials Icons/action/speaker_notes_24px.svg";
import Warning from "../icon/Google Materials Icons/alert/warning_amber_24px.svg";
import People from "../icon/Google Materials Icons/social/people_outline_24px.svg";
import Comment from "../icon/Google Materials Icons/communication/comment_24px.svg";
import Swap from "../icon/Google Materials Icons/action/swap_horizontal_circle_24px.svg";
import Send from "../icon/Google Materials Icons/content/send_24px.svg";
import Edit from "../icon/Google Materials Icons/image/edit_24px.svg";
import Search from "../icon/Google Materials Icons/action/search_24px.svg";
import Flip from "../icon/Google Materials Icons/notification/sync_24px.svg";
import VSwap from "../icon/Google Materials Icons/action/swap_vert_24px.svg";
import AlertBell from "../icon/Google Materials Icons/alert/notification_important_24px.svg";
import LandingPageAI from "../icon/custom-icons/AI_96px.svg";
import LandingPageAlert from "../icon/custom-icons/alert_96px.svg";
import LandingPageChatbot from "../icon/custom-icons/chatbot_96px 2.svg";
import LandingPageClean from "../icon/custom-icons/clean_96px.svg";
import LandingPageConnect from "../icon/custom-icons/connect_96px 2.svg";
import LandingPageData from "../icon/custom-icons/data_96px.svg";
import LandingPageEmailAlert from "../icon/custom-icons/emailalert_96px.svg";
import LandingPageWebScraping from "../icon/custom-icons/webscraping_96px.svg";
import LandingPageWorkFlow from "../icon/custom-icons/workflow_96px.svg";

class IconSVG extends React.Component {
    render() {
        // let percentage = this.props.size * parseInt(getComputedStyle(document.documentElement).fontSize) / 24;
        let percentage =
            (parseFloat(getComputedStyle(document.documentElement).fontSize) /
                16) *
            this.props.size;
        const iconWrapperStyle = {
            //default is 24px by 24px
            transform: "scale(" + percentage + ")",
            transformOrigin:
                this.props.center === "left_top" ? "0 0 0" : "50% 50% 0",
            display: "inline-flex",
            ...this.props.wrapperStyle
        };
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div style={iconWrapperStyle} onClick={this.props.onClick}>
                {this.props.name === "archive" && (
                    <ArchiveIconSVG
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "newRisk" && (
                    <AddCircleOutline
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "home" && (
                    <HomeIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "delete" && (
                    <DeleteIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "calendar" && (
                    <CalendarIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "work" && (
                    <WorkOutlineIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "moreHori" && (
                    <MoreHoriIcon
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "commitActive" && (
                    <VersionCommitActive
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "commitInactive" && (
                    <VersionCommitInactive
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "branchActive" && (
                    <VersionBranchActive
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "branchInactive" && (
                    <VersionBranchInactive
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "listNumber" && (
                    <ListNumber
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "barChart" && (
                    <BarChart
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "file" && (
                    <File style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "info" && (
                    <Info style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "cancel" && (
                    <Cancel
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "speaker" && (
                    <Speaker
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "warning" && (
                    <Warning
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "people" && (
                    <People
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "comment" && (
                    <Comment
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "swap" && (
                    <Swap style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "send" && (
                    <Send style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "edit" && (
                    <Edit style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "search" && (
                    <Search
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "flip" && (
                    <Flip style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "vSwap" && (
                    <VSwap style={{ margin: "auto" }} fill={this.props.color} />
                )}
                {this.props.name === "alert" && (
                    <AlertBell
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "landingPageAI" && (
                    <LandingPageAI
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "landingPageAlert" && (
                    <LandingPageAlert
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "landingPageChatbot" && (
                    <LandingPageChatbot
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "landingPageClean" && (
                    <LandingPageClean
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "landingPageConnect" && (
                    <LandingPageConnect
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "landingPageData" && (
                    <LandingPageData
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "landingPageEmailAlert" && (
                    <LandingPageEmailAlert
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "landingPageWebScraping" && (
                    <LandingPageWebScraping
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
                {this.props.name === "landingPageWorkFlow" && (
                    <LandingPageWorkFlow
                        style={{ margin: "auto" }}
                        fill={this.props.color}
                    />
                )}
            </div>
        );
    }
}

export { IconSVG };
