import { mapping } from "./mapping";

const inputStyle = {
    height: mapping["Field_Height"],
    width: "100%",
    borderWidth: 0,
    backgroundColor: mapping["Field_BG_Color"], //["Color_BG_03_trans"],
    border: "none !important",
    color: mapping["Color_Basic_Light"],
    padding: "0.25rem",
    resize: "none",
    overflow: "hidden",
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    borderRadius: 0,
    outline: "none"
};

const userProfileStyle = {
    PanelStyle: {
        position: "relative",
        float: "left",
        backgroundColor: "#2F3B52",
        height: "auto",
        width: "100rem",
        borderRadius: "0.625rem",
        margin: "0.5rem",
        marginTop: "2rem",
        padding: "4rem",
        display: "flex",
        flexDirection: "row"
    },
    ContainerStyle: {
        height: "auto",
        width: "32rem",
        marginRight: "4rem",
        // marginTop: "4rem",
        // marginBottom: "3rem",
        // paddingTop: "3rem",
        paddingBottom: "1rem",
        display: "flex",
        flexDirection: "column"
        // backgroundColor: "lightblue",
    },
    AvatarBG: {
        borderRadius: "50%",
        backgroundColor: mapping["Color_Card_Shadow"],
        height: "20rem",
        width: "20rem",
        padding: "0.75rem"
    },
    TextLineStyle: {
        height: "2.875rem",
        width: "32rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "1rem"
        // backgroundColor: "red",
    },

    InputFieldStyle: {
        ...inputStyle,
        width: "20rem"
    },
    contentStyle: {
        ...mapping["paragraph/default/lightleft"],
        fontSize: "0.9375rem",
        height: "0.9375rem",
        paddingLeft: "0.25rem"
    },
    nameStyle: {
        ...mapping["heading/h4/lightleft"],
        marginBottom: "1rem",
        fontSize: "1.875rem"
    },
    //-------------------changepasswordpopup.js---------------------------//

    popupInputFieldStyle: {
        ...inputStyle,
        width: "100%"
    },
    formBgStyle: {
        height: "fit-content",
        width: "25rem",
        padding: "3rem 1.5rem 3rem 1.5rem",
        backgroundColor: mapping["Color_Extra_Popup_BG"],
        //display: "inline-flex",
        position: "relative"
    },
    RiskCreationFormStyle: {
        position: "relative",
        left: "-50%"
    },
    RiskCreationFormWrapStyle: {
        position: "absolute",
        left: "50%",
        top: "13.3rem"
    },
    buttonHeight: "2.25rem",
    buttonWidth: "5rem",
    buttonWrapper: {
        display: "inline-flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "5rem"
    },
    fieldTitleWrapper: {
        height: "1rem",
        width: "100%",
        marginBottom: "0.75rem"
    },
    warningMSGStyle: {
        ...mapping["inlinetext/Italicized/lightleft"],
        fontSize: "0.875rem",
        color: mapping["Color_Basic_Danger"]
    }
};

export { userProfileStyle };
