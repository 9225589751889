import { mapping } from "./mapping";
const inputStyle = {
    height: mapping["Field_Height"],
    borderWidth: 0,
    backgroundColor: mapping["Field_BG_Color"], //["Color_BG_03_trans"],
    border: "none !important",
    color: mapping["Color_Basic_Light"],
    padding: "0.25rem",
    resize: "none",
    overflow: "hidden",
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    borderRadius: 0,
    outline: "none"
};

const stepStyle = {
    ...mapping["heading/h3/lightcenter"],
    height: "2.5rem",
    width: "2.5rem",
    padding: "0.2rem 0.2rem 0.2rem 0.2rem",
    display: "inline-block",
    fontSize: "1.25rem",
    borderRadius: "50%",
    border: "solid 0.156rem",
    borderColor: "#999999",
    color: "#999999",
    fontWeight: "bold"
};
const userAdminStyle = {
    //--------------------common-------------------------------//

    BlurEffectStyle: mapping["BlurEffectStyle"],
    gridStyle: {
        maxHeight: "40rem",
        height: "fit-content",
        fontSize: "0.875rem",
        marginBottom: "1.25rem"
    },
    headerCellStyle: mapping["headerCellStyle"],
    toolBarWrapper: {
        margin: "1rem",
        display: "flex",
        justifyContent: "space-between"
    },
    toolFlexEndWrapper: {
        marginRight: "1rem",
        display: "flex",
        justifyContent: "flex-end"
    },
    cancelButtonWidth: "4.9375rem",
    submitButtonWidth: "12.3125rem",
    roleUpdateButtonWidth: "7.5rem",
    titleCreateButtonWidth: "6.875rem",
    buttonHeight: "2.4375rem",
    //----------------------adminDashboard.js-------------------//
    pageWrapper: {
        width: "102.5rem",
        margin: "2.1875rem 0 0 10.125rem"
    },
    DarkBoxStyle: {
        backgroundColor: mapping["Color_BG_03_trans"],
        width: "18rem",
        height: "22rem",
        borderRadius: "0.25rem",
        marginRight: "1.1rem",
        padding: "1.1rem",
        display: "flex",
        flexDirection: "column"
    },
    //-----------------------------logTable.js----------------------------//

    userTableWrapper: {
        marginLeft: "15.6rem",
        marginRight: "15.6rem",
        marginTop: "3.9rem",
        height: "fit-content",
        width: "fit-content",
        backgroundColor: "rgba(0,0,0,0.4)"
    },
    tableTitlePadding: {
        paddingLeft: "1rem",
        paddingTop: "1rem"
    },
    titleStyle: {
        marginBottom: "1rem",
        ...mapping["heading/h5/lightleft"]
    },
    timeFilter: {
        width: "25rem",
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    dateSeparatorFont: {
        ...mapping["forms/label/1-default"],
        color: "white",
        marginLeft: "0.5rem",
        marginRight: "0.5rem"
    },
    iconWrapper: {
        marginLeft: "0.5rem",
        width: "2rem",
        height: "2rem",
        backgroundColor: mapping["Color_Basic_Primary"]
    },
    tableIconStyle: {
        display: "inline-flex",
        placeContent: "center",
        width: "100%"
    },
    //------------------------------popup------------------------//
    formBgStyle: {
        height: "fit-content",
        width: "36.1875rem",
        padding: "1.4375rem 1.125rem 2.6875rem 1.125rem",
        backgroundColor: mapping["Color_Extra_Half_Transparent"],
        //display: "inline-flex",
        position: "relative"
    },
    RiskCreationFormStyle: {
        position: "relative",
        left: "-50%"
    },
    RiskCreationFormWrapStyle: {
        position: "absolute",
        left: "50%",
        top: "13.3rem"
    },

    InputFieldStyle: inputStyle,
    //-------------------roleList.js------------------------------//
    OUDropDownButtonStyle: {
        width: "11.875rem",
        height: "2rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    },
    //-------------------rolePopup--------------------------------//

    roleOUDropDownButtonStyle: {
        width: "100%",
        height: "2.5rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    },
    fieldTitleStyle: {
        ...mapping["Form_Label_Padding"],
        display: "flex",
        paddingBottom: "0.5625rem",
        color: mapping["Color_Basic_Light"]
    },

    checkboxStyle: {
        display: "inline-flex",
        justifyContent: "space-between",
        width: "100%"
    },

    fieldPadding: {
        height: "1.25rem"
    },
    halfFieldPadding: {
        height: "0.625rem"
    },
    roleInputFieldStyle: {
        ...inputStyle,
        width: "100%"
    },
    roleFormBgStyle: {
        height: "fit-content",
        width: "28.125rem",
        padding: "1.4375rem 1.125rem 2.6875rem 1.125rem",
        backgroundColor: mapping["Color_Extra_Half_Transparent"],
        //display: "inline-flex",
        position: "relative"
    },
    //----------------------------account--------------------------------------------//

    buttonWrapper: {
        display: "inline-flex",
        paddingTop: "4rem",
        width: "100%",
        justifyContent: "space-between"
    },

    titleDropDownButtonStyle: {
        width: "100%",
        height: "2.5rem",
        //backgroundColor: mapping["Color_Basic_Secondary"],
        backgroundColor: mapping["Field_BG_Color"],
        border: "none",
        boxShadow: "none"
    },
    PopupStyle: {
        backgroundColor: "rgba(0,0,0,0.6)",
        padding: "1rem",
        height: "auto",
        width: "36rem"
    },
    checkBoxWrapperStyle: {
        marginLeft: "0.5rem",
        marginBottom: "0.5rem"
    },
    // -------------------------------ValidationErrorPopup.js------------------//
    creationPageStyle: {
        position: "absolute",
        right: 0,
        width: "100vw",
        textAlign: "left",
        color: "white"
    },
    creationCompleteStyle: {
        margin: "auto",
        width: "30%",
        fontSize: 18,
        backgroundColor: "#283A5D",
        boxShadow: "0px 0px 0.3125rem 0.2px black",
        padding: "0.5rem 0",
        zIndex: 4,
        position: "relative"
    },
    accountCreatedStyle: {
        marginLeft: "1rem",
        fontSize: 16,
        marginBottom: "0.5rem",
        marginTop: "0.75rem",
        fontStyle: "bold"
    },
    emailSentStyle: {
        marginLeft: "1rem",
        fontSize: 12,
        marginTop: "0.75rem",
        marginBottom: "1.75rem",
        fontStyle: "bold"
    },
    lineStyle: {
        borderWidth: "0.0625rem",
        borderColor: "#777171",
        zIndex: 4,
        position: "relative"
    },
    buttonPosition: {
        textAlign: "right",
        marginRight: "1rem"
    },
    //---------------------------userSearch.js-----------------------//

    userSearchTitleStyle: {
        ...mapping["forms/label/1-default"],
        marginBottom: "0.5625rem"
    },
    searchDirectory: {
        ...mapping["heading/h3/lightcenter"],
        width: "28.75rem",
        margin: "1.5rem auto",
        marginTop: "2.125rem",
        textAlign: "left"
    },
    //----------------------userCreate.js-----------------------//

    userCreateTitleStyle: {
        ...mapping["heading/h3/lightcenter"],
        marginTop: "1.5rem",
        fontSize: "1.75rem"
    },
    stepStyle: stepStyle,
    stepHighlightedStyle: {
        ...stepStyle,
        borderColor: "#00A2FF",
        color: "white",
        fontWeight: "bold"
    },
    lineStyleHighlighted: {
        borderWidth: "0.0625rem",
        width: "7.25rem",
        display: "inline-block",
        borderStyle: "dashed",
        borderColor: "#00A2FF"
    },
    lineBetweenTitle: {
        width: "3.75rem",
        display: "inline-block",
        borderStyle: "dashed",
        borderColor: "white"
    },
    searchUser: {
        ...mapping["heading/h3/lightcenter"],
        width: "6.25rem",
        display: "inline-block",
        fontSize: "1rem",
        overflow: "auto",
        color: "#999999"
    },
    searchUserHighlighted: {
        ...mapping["heading/h3/lightcenter"],
        width: "6.25rem",
        display: "inline-block",
        fontSize: "1rem",
        overflow: "auto"
    },
    // --------------------------accountDetail.js-------------------//

    accountDetailSearchDirectory: {
        ...mapping["heading/h3/lightcenter"],
        position: "relative",
        width: "28.75rem",
        margin: "0.5rem auto",
        textAlign: "left",
        backgroundColor: "#182237",
        padding: "1rem"
    },
    accountDetailButtonPosition: {
        display: "flex",
        justifyContent: "space-between",
        width: "28.75rem",
        margin: "1.5rem auto"
    },
    rightButtonPosition: {
        display: "inline-flex",
        justifyContent: "space-between"
    },

    userInfoStyle: {
        ...mapping["heading/h5/lightleft"],
        marginBottom: "1rem"
    },
    userDetailsStyle: {
        marginBottom: "0.75rem",
        fontSize: "0.875rem"
    },
    userPhoneStyle: {
        fontSize: "0.875rem",
        marginBottom: "0.5rem"
    },

    accountDetailFieldPadding: {
        height: "0.625rem"
    },

    formCheckBoxStyle: {
        width: "100%",
        display: "inline-flex",
        justifyContent: "space-between"
    },

    checkBoxTitleStyle: {
        ...mapping["forms/label/1-default"],
        ...mapping["Form_Label_Padding"],
        display: "flex",
        paddingBottom: "0.5625rem",
        color: mapping["Color_Basic_Light"]
    },

    // titleDropDownButtonStyle: {
    //     width: "100%",
    //     height: "32px",
    //     backgroundColor: mapping["Field_BG_Color"],
    //     border: "none",
    //     boxShadow: "none",
    // },
    ValidFieldStyle: {
        height: mapping["Field_Height"],
        width: "100%",
        borderWidth: 0,
        backgroundColor: mapping["Field_BG_Color"],
        border: "none !important",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        color: mapping["Color_Basic_Light"]
    },
    InvalidFieldStyle: {
        height: mapping["Field_Height"],
        width: "100%",
        borderWidth: 0,
        backgroundColor: mapping["Field_BG_Color"],
        border: "none !important",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        color: mapping["Color_Basic_Light"],
        boxShadow: "0 0 0 0.125rem rgb(220,53,69)"
    },
    //  --------------roleAssign.js------------------------//
    userRoleAssignStyle: {
        margin: "1.5rem auto",
        textAlign: "left",
        color: "white",
        backgroundColor: "#182237",
        padding: "0.5rem"
    },
    roleAssignButtonPosition: {
        display: "flex",
        justifyContent: "space-between",
        width: "58.75rem",
        margin: "1.5rem auto"
    },
    tabStyle: {
        fontSize: "0.75rem",
        marginBottom: "1.5rem"
    },
    //---------------------------creationComplete.js--------------------//
    creationCompletePageStyle: {
        margin: "-20rem auto",
        textAlign: "left",
        color: "white"
    }
};

export { userAdminStyle };
