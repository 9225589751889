import React from "react";
import { mapping,  } from "../style/mapping";
import { alertManagementStyle, } from "../style/alertManagementStyle";
import {riskEditStyle} from "../style/riskEditStyle";
import {NonValidationInput} from "./Field";

class AlertDetailSpecialCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
                <div>
                    <div style={alertManagementStyle.sectionTitleStyle}>
                        {this.props.data.header}
                    </div>
                </div>
                {this.props.data.content}
            </div>
        );
    }
}

export { AlertDetailSpecialCard };
