import { mapping } from "./mapping";
import { sideMenuStyle } from "./sideMenuStyle";

const alertManagementStyle = {
    ...sideMenuStyle,
    headerCellStyle: mapping["headerCellStyle"],
    titleCategoryFont: {
        whiteSpace: "pre-wrap",
        height: "fit-content",
        width: "12.6875rem",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "1.125rem",
        fontWeight: 500,
        letterSpacing: "0.3px",
        lineHeight: "1.5rem",
        paddingTop: "2.56rem",
        paddingBottom: "2.15rem",
        paddingLeft: "1.125rem"
    },

    subMenuFont: {
        ...mapping["paragraph/small/lightleft"],
        height: "fit-content",
        alignItems: "center",
        marginLeft: "1.4rem"
    },

    longTextHidden: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "85%"
    },

    parentMenuFont: {
        height: "fit-content",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "0.8125rem",
        fontWeight: "bold",
        letterSpacing: 0,
        lineHeight: "1.25rem",
        display: "flex",
        alignItems: "center",
        marginLeft: "1rem"
    },

    MenuWrapper: {
        position: "relative",
        height: "fit-content",
        backgroundColor: "#131821",
        borderBottom: "0.0625rem solid #232C3D",
        paddingTop: "0.625rem",
        paddingBottom: "0.625rem"
    },
    MenuWrapperHover: {
        position: "relative",
        height: "fit-content",
        backgroundColor: "#232C3D",
        borderBottom: "0.0625rem solid #232C3D",
        paddingTop: "0.625rem",
        paddingBottom: "0.625rem"
    },
    indicatorBar: {
        position: "absolute",
        width: "0.375rem",
        top: 0,
        height: "100%",
        backgroundColor: mapping["Color_Basic_Primary"]
    },
    //----------------------alertSummaryDetail.js-----------------------//
    detailWrapper: {
        display: "inline-flex",
        margin: "2.5rem",
        marginTop: "6.875rem",
        marginLeft: "5.625rem"
    },
    //----------------------alertManagement.js---------------------------//
    BlurEffectStyle: mapping["BlurEffectStyle"],
    breadCumStyle: {
        display: "inline-flex",
        alignItems: "center",
        marginTop: "1.0625rem",
        marginBottom: "1.5rem"
    },
    buttonHeight: "1.9375rem",
    buttonWidth: "4.0625rem",
    reportButtonWidth: "fit-content",
    transferButtonWidth: "fit-content",
    //--------------------alertSummaryDescription.js---------------//
    descriptionWrapperStyle: {
        width: "21.25rem",
        height: "23.125rem"
    },
    titleFontStyle: {
        height: "fit-content",
        borderWidth: 0,
        border: "none !important",
        color: mapping["Color_Basic_Light"],
        resize: "none",
        overflow: "hidden",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        borderRadius: 0,
        outline: "none",
        fontSize: "1.875rem",
        fontWeight: 500,
        background: "none",
        paddingBottom: "0.9375rem"
    },
    descriptionFontStyle: {
        height: "fit-content",
        borderWidth: 0,
        border: "none !important",
        color: mapping["Color_Basic_Light"],
        resize: "none",
        overflow: "hidden",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        borderRadius: 0,
        outline: "none",
        background: "none"
    },
    separatorFontStyle: {
        ...mapping["Sub Text Tag/H7-blue-tag"],
        color: "#00BEFF",
        paddingBottom: "1.25rem"
    },
    //---------------summary/alertDataChart.js----------------//

    NoValueStyle: {
        color: "white"
    },

    chartWrapper: {
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "43.125rem",
        height: "18.3125rem",
        backgroundColor: "#35425C",
        boxShadow: "0 0 0.9375rem rgba(0,0,0,0.3)"
    },

    cardWrapper: {
        width: "45.625rem",
        height: "23.125rem",
        overflow: "auto",
        backgroundColor: mapping["Color_BG_04_solid"],
        borderRadius: "0.625rem",
        boxShadow: "0 0 0.9375rem rgba(0,0,0,0.3)" // X axis, Y axis ,blur, colour
    },
    mainHeader: {
        display: "inline-flex",
        paddingLeft: "1.0625rem",
        paddingTop: "0.79875rem",
        paddingBottom: "0.625rem",
        color: "white",
        ...mapping["heading/h5/lightleft"]
    },
    //------------------------summaryTable.js--------------------//
    tableWrapper: {
        paddingLeft: "5rem",
        width: "75rem"
    },
    gridStyle: {
        maxHeight: "40rem",
        height: "fit-content",
        fontSize: "0.875rem",
        marginBottom: "1.25rem"
    },
    //--------------------alertBasicInfo.js------------------------//

    shortContentStyle: {
        ...mapping["forms/label/1-default"],
        padding: 0,
        paddingLeft: "0.8125rem",
        display: "flex",
        alignItems: "center",
        marginTop: "0.5625rem",
        marginRight: "2.5rem",
        height: "2.5rem",
        width: "15.625rem",
        backgroundColor: "rgba(0,0,0,0.15)",
        color: "white"
    },

    contentStyle: {
        ...mapping["forms/label/1-default"],
        padding: 0,
        paddingLeft: "0.8125rem",
        display: "flex",
        alignItems: "center",
        marginTop: "0.5625rem",
        height: "2.5rem",
        width: "33.75rem",
        backgroundColor: "rgba(0,0,0,0.15)",
        color: "white"
    },

    titleStyle: {
        ...mapping["heading/h5/lightleft"],
        padding: "0 0 2.8125rem 0"
    },

    wrapperStyle: {
        position: "relative",
        padding: "1.10625rem 0 0 1.0625rem",
        height: "fit-content",
        width: "36.25rem",
        borderRadius: "0.625rem",
        backgroundColor: "rgba(0,0,0,0.4)"
    },

    InputFieldStyle: {
        height: mapping["Field_Height"],
        width: "63rem",
        borderWidth: "0",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        border: "none !important",
        color: mapping["Color_Basic_Light"],
        padding: "0.25rem",
        resize: "none",
        overflow: "hidden",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        borderRadius: 0,
        outline: "none"
    },
    centerStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "1rem"
    },
    twoButtonsCenter: {
        width: "100%",
        paddingRight: "1.25rem",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: "1rem"
    },

    transferMessageTitleStyle: {
        ...mapping["forms/label/1-default"],
        margin: "1.3125rem 0 0 1.5rem",
        color: mapping["Color_Basic_Primary"]
    },
    messageCommentStyle: {
        margin: "0 0 0.4375rem 1.875rem"
    },
    messageWrapper: {
        height: "5.0625rem",
        width: "100%",
        display: "inline-flex"
    },
    messageStyle: {
        width: "0.275rem",
        height: "5.0625rem"
    },
    basicInfoButtonHeight: "2.375rem",
    basicInfoButtonWidth: "10.0625rem",
    //----------------------------taskReference.js----------------//
    taskStyleWrapper: {
        position: "relative",
        margin: "0 0 1.25rem 1.25rem",
        padding: "1rem 0 0 1.0625rem",
        height: "13.0625rem",
        width: "33.75rem",
        backgroundColor: "#212938"
    },
    taskTitleStyle: {
        ...mapping["heading/h5/lightleft"],
        padding: "1.0625rem 0 2.5rem 1.0625rem"
    },
    taskShortContentStyle: {
        ...mapping["forms/label/1-default"],
        padding: 0,
        paddingLeft: "0.8125rem",
        display: "flex",
        alignItems: "center",
        marginTop: "0.5625rem",
        marginRight: "2.5rem",
        height: "2.5rem",
        width: "9.0625rem",
        backgroundColor: "rgba(0,0,0,0.15)",
        color: "white"
    },
    taskContentStyle: {
        ...mapping["forms/label/1-default"],
        padding: 0,
        paddingLeft: "0.8125rem",
        display: "flex",
        alignItems: "center",
        marginTop: "0.5625rem",
        marginRight: "2.5rem",
        height: "2.5rem",
        width: "32rem",
        backgroundColor: "rgba(0,0,0,0.15)",
        color: "white"
    },
    taskWrapperStyle: {
        position: "relative",
        paddingBottom: "1.25rem",
        borderRadius: "0.625rem",
        backgroundColor: "rgba(0,0,0,0.4)",
        height: "fit-content",
        width: "36.3125rem"
    },
    sectionTitleStyle: {
        ...mapping["Sub Text Tag/H7-blue-tag"],
        color: "#00BEFF",
        marginBottom: "1rem"
    },
    createTaskButtonWrapperStyle: {
        position: "absolute",
        top: "0.5em",
        right: "1.125rem"
    },
    iconButtonHeight: "2rem",
    iconButtonWidth: "fit-content",
    //----------------------alertIAEDetail.js-----------------------//
    requestContentDisabledStyle: {
        ...mapping["forms/label/1-default"],
        padding: 10,
        paddingLeft: "0.8125rem",
        display: "flex",
        alignItems: "center",
        height: "fit-content",
        minHeight: "2.5rem",
        width: "inherit",
        backgroundColor: "rgba(0,0,0,0.15)",
        color: "white"
    },
    detailTopContentStyle: {
        ...mapping["forms/label/1-default"],
        padding: 10,
        paddingLeft: "0.8125rem",
        display: "flex",
        alignItems: "center",
        marginTop: "0.5625rem",
        height: "fit-content",
        minHeight: "2.5rem",
        width: "inherit",
        backgroundColor: "rgba(0,0,0,0.15)",
        color: "white"
    },
    detailTopDetailStyleWrapper: {
        position: "relative",
        margin: "0 0 1.25rem 1.25rem",
        padding: "1.25rem 0 1.25rem 1.6875rem",
        width: "65.875rem",
        backgroundColor: "#212938"
    },
    //------------------------------vendorAnalysis.js----------------//
    bubbleChartWidth: "30.1875rem",
    bubbleChartHeight: "17.0625rem",
    //-----------------------------alertManagement.js------------------//
    alertManagementWrapperStyle: {
        overflow: "hidden",
        height: "fit-content",
        width: "68.75rem",
        borderRadius: "0.625rem",
        backgroundColor: "rgba(0,0,0,0.4)"
    },
    //--------------------vendorScore------------------------//
    boxPlotStyle: {
        display: "inline-flex",
        backgroundColor: "#00000050",
        padding: "0.4375rem",
        paddingRight: "0.625rem"
    },
    boxPlotTitleFont: {
        height: "1.59375rem",
        width: "5.3125rem",
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "0.491rem",
        fontWeight: "500",
        letterSpacing: "0.0375rem",
        lineHeight: "0.625rem",
        textAlign: "center"
    },
    //------------------------------popup-----------------------//
    popupInputFieldStyle: {
        height: mapping["Field_Height"],
        width: "100%",
        borderWidth: 0,
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        border: "none !important",
        color: mapping["Color_Basic_Light"],
        padding: "0.25rem",
        resize: "none",
        overflow: "hidden",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        borderRadius: 0,
        outline: "none"
    },
    formBgStyle: {
        height: "fit-content",
        width: "36.1875rem",
        padding: "1.4375rem 1.125rem 2.6875rem 1.125rem",
        backgroundColor: mapping["Color_Extra_Half_Transparent"],
        //display: "inline-flex",
        position: "relative"
    },
    RiskCreationFormStyle: {
        position: "relative",
        left: "-50%"
    },
    RiskCreationFormWrapStyle: {
        position: "absolute",
        left: "50%",
        top: "13.3rem"
    },
    popupButtonHeight: "2.4375rem",
    popupCancelButtonWidth: "4.9375rem",
    popupSubmitButtonWidth: "12.3125rem",
    //-----------------------alertKRIDetail.js-----------------------------//

    KRITitleStyle: {
        ...mapping["heading/h5/lightleft"],
        padding: "1.125rem 0 2.5rem 1.0625rem"
    },
    KRIWrapperStyle: {
        height: "fit-content",
        width: "68.75rem",
        borderRadius: "0.625rem",
        backgroundColor: "rgba(0,0,0,0.4)"
    },

    KRIContentStyle: {
        ...mapping["forms/label/1-default"],
        padding: 0,
        paddingLeft: "0.8125rem",
        display: "flex",
        alignItems: "center",
        marginTop: "0.5625rem",
        height: "2.5rem",
        width: "inherit",
        backgroundColor: "rgba(0,0,0,0.15)",
        color: "white"
    },

    detailStyleWrapper: {
        position: "relative",
        margin: "0 0 1.25rem 1.25rem",
        padding: "1.25rem 0 1.25rem 1.6875rem",
        width: "65.875rem",
        backgroundColor: "#212938"
    },

    riskThresholdTypeDropDownButtonStyle: {
        width: "4rem",
        height: "2rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    }
};

export { alertManagementStyle };
