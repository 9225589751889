import { mapping } from "./mapping";
import { sideMenuStyle } from "./sideMenuStyle";

const InputField = {
    height: mapping["Field_Height"],
    width: "63rem",
    borderWidth: 0,
    backgroundColor: mapping["Color_Form_00_Field_Solid"],
    border: "none !important",
    color: mapping["Color_Basic_Light"],
    padding: "0.25rem",
    resize: "none",
    overflow: "hidden",
    //outline:mapping['Field_Outline']+' solid '+borderColor,
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    borderRadius: 0,
    outline: "none"
};

const CSAStyle = {
    BlurEffectStyle: mapping["BlurEffectStyle"],

    ControlCSASideStyle: {
        height: "fit-content",
        width: "120rem",
        display: "inline-flex"
    },

    ControlSelfAssessmentContentWrapperStyle: {
        height: "fit-content",
        width: "68.75rem"
    },
    submitButtonWrapper: {
        display: "flex",
        justifyContent: "flex-end",
        width: "inherit",
        marginTop: "5rem"
    },
    buttonHeight: "1.94rem",
    submitButtonWidth: "7.9375rem",
    addButtonHeight: "1.125rem",
    addButtonWidth: "3.25rem",

    //-----------------Side Menu-------------------------//
    ...sideMenuStyle,
    //-----------------controlBasic.js-------------------//

    AttachmentWrapper: {
        paddingLeft: "1.6875rem",
        marginTop: "0.3125rem",
        marginBottom: "0.3125rem",
        display: "inline-flex",
        alignItems: "center",
        height: "2.5rem",
        width: "22.0625rem",
        borderRadius: "1.5rem",
        backgroundColor: mapping["Color_BG_03_trans"]
    },

    AttachmentStyle: {
        paddingLeft: "0.8125rem",
        height: "1.375rem",
        width: "13.375rem",
        fontFamily: "Roboto",
        fontSize: "1rem",
        letterSpacing: "0.2px",
        lineHeight: "1.375rem",
        color: mapping["Color_Basic_Info"],
        textDecoration: "underline"
    },

    AttachmentDeleteButtonWrapperStyle: {
        margin: "auto",
        marginRight: "1.4375rem",
        height: "1.5rem",
        width: "1.5rem",
        borderRadius: "0.1875rem",
        backgroundColor: mapping["Color_Basic_Primary"]
    },

    BasicInfoStyle: {
        width: "100%",
        height: "fit-content",
        paddingTop: "1rem",
        paddingLeft: "5rem"
    },

    ShortTextStyle: {
        ...mapping["forms/input-small"],
        overflow: "hidden",
        height: "2rem",
        border: "none",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        padding: "0.5625rem 0 0 0.8125rem",
        borderRadius: 0,
        width: "9rem",
        borderWidth: 0,
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        color: mapping["Color_Basic_Light"]
    },

    ShortMultilineInputFieldStyle: {
        resize: "none",
        overflow: "hidden",
        height: "auto",
        border: "none",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        borderRadius: 0,
        width: "20.875rem",
        borderWidth: 0,
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        color: mapping["Color_Basic_Light"]
    },

    sectionTitleStyle: {
        ...mapping["Sub Text Tag/H7-blue-tag"],
        color: "#00BEFF",
        paddingBottom: "1.25rem",
        whiteSpace: "nowrap"
    },

    fakeButtonStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.25rem",
        ...mapping["Font_Button_Small_Primary"],
        width: "3.625rem",
        height: "1.375rem",
        color: "white",
        backgroundColor: mapping["Color_Basic_Primary"]
    },
    fileUploadWrapper: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "0.3125rem"
    },
    //-----------------controlDetail--------------------//

    ControlDetailWrapperStyle: {
        width: "100%",
        height: "fit-content",
        marginTop: "1rem",
        marginLeft: "5rem",
        backgroundColor: mapping["Color_BG_03_trans"]
    },
    ControlDetailDropDownsWrapperStyle: {
        width: "65.6rem",
        height: "fit-content",
        margin: "1.5rem",
        backgroundColor: "rgba(62,78,108,0.5)",
        display: "inline-flex"
    },
    DropDownContentStyle: {
        width: "17rem",
        padding: "1rem 2.2rem 1rem 1rem"
    },
    riskTierDropDownButtonStyle: {
        width: "100%",
        height: "2rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    },
    //----------------CSADocument.js---------------------//
    ControlDocumentWrapperStyle: {
        paddingBottom: "2.5rem",
        width: "100%",
        height: "fit-content",
        marginTop: "5rem",
        marginLeft: "5rem",
        backgroundColor: mapping["Color_BG_03_trans"]
    },
    // ------------CSADocumentAssessment.js--------------//
    ControlDocumentAssessmentDropDownsWrapperStyle: {
        position: "relative",
        width: "65.6rem",
        height: "fit-content",
        margin: "1.5rem",
        marginBottom: 0,
        backgroundColor: mapping["Color_BG_05_solid"]
    },
    InputFieldStyle: InputField,
    CardTitleDateStyle: {
        ...mapping["heading/h5/lightleft"],
        padding: "1rem 0 3rem 1rem"
    },
    completeCheckBoxStyle: {
        position: "absolute",
        right: "1rem",
        top: "1rem",
        color: "white"
    },
    commentStyle: {
        marginLeft: "1.25rem",
        marginBottom: "2rem",
        marginTop: "6.875rem"
    },
    //-----------------controlDocumentRP.js--------------//
    RPInputFieldStyle: {
        ...InputField,
        marginBottom: "2.37rem"
    },
    ControlDocumentRPDropDownsWrapperStyle: {
        paddingBottom: "2.5rem",
        paddingTop: "2.9375rem",
        width: "65.6rem",
        height: "fit-content",
        margin: "0 1.5rem 0 1.5rem ",
        backgroundColor: mapping["Color_BG_05_solid"]
    },
    addNewButtonWrapper: {
        display: "flex",
        backgroundColor: mapping["Color_BG_02_solid"],
        margin: "0.375rem 1.25rem 0 1.25rem",
        padding: "1rem"
    },
    addNewButtonWidth: "fit-content",
    addNewButtonHeight: "1.9375rem",
    //----------------CSADocumentTestResult--------------//
    TestResultContentStyle: {
        ...mapping["forms/input-small"]
    },

    testResultContentWrapperStyle: {
        width: "63.05rem",
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        padding: "1.25rem 0 0 1.25rem",
        marginLeft: "1.25rem",
        backgroundColor: mapping["Color_BG_02_solid"]
    },

    ControlDocumentTestResultDropDownsWrapperStyle: {
        width: "65.6rem",
        height: "fit-content",
        margin: "0 1.5rem 0 1.5rem",
        backgroundColor: mapping["Color_BG_05_solid"]
    },
    remarkLinkWrapper: {
        display: "flex",
        justifyContent: "center",
        width: "5.625rem",
        marginRight: "1.5625rem"
    },
    sectionTitleStyleWrap: {
        ...mapping["Sub Text Tag/H7-blue-tag"],
        color: "#00BEFF",
        paddingBottom: "1.25rem"
    },
    //-----------------remark.js-------------------------//
    remarkButtonHeight: "2.375rem",
    remarkButtonWidth: "7.5rem",

    MultilineInputFieldStyle: {
        padding: "4px",
        resize: "none",
        overflow: "hidden",
        height: "auto",
        border: "none",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        borderRadius: 0,
        width: "100%",
        borderWidth: "0px",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        color: mapping["Color_Basic_Light"],
        outline: "none"
    },
    contentWrapper: {
        width: "33.75rem",
        height: "fit-content",
        backgroundColor: "rgba(0,0,0,0.4)",
        padding: "1.2rem"
    },
    remarkFormStyle: {
        position: "relative",
        left: "-50%"
    },
    remarkFormWrapStyle: {
        position: "fixed",
        left: "50%",
        top: "13.3rem"
    },

    buttonWrapper: {
        display: "inline-flex",
        paddingTop: "31px",
        width: "100%",
        justifyContent: "space-between"
    }
};

export { CSAStyle };
