import React from "react";
import { mapping } from "../../style/mapping";
import { ABCDemoRiskTile } from "./ABCDemoRiskTile";
import "@progress/kendo-theme-bootstrap/dist/all.css";

const RiskTowerActiveBGColor = mapping["Color_Risk_Tower_Active"];
const RiskTowerInactiveBGColor =
    mapping["Color_Risk_Tower_Active"] +
    mapping["Color_Risk_Tower_Inactive_Transparent"];

const TotalRiskIcon = props => {
    const outlineStyle = {
        width: "1.5rem",
        height: "1.5rem",
        borderRadius: "0.25rem",
        backgroundColor: mapping["Color_Extra_Transparent"],
        border: "1px solid #9EB7C5"
    };
    return (
        <div style={outlineStyle}>
            <div style={mapping["paragraph/small/lightcenter"]}>
                {props.totalNumber}
            </div>
        </div>
    );
};

class ABCDemoRiskTower extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: this.props.category,
            riskList: this.props.riskList,
            hover: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            riskList: nextProps.riskList
        };
    }

    render() {
        var RiskTowerStyle = {
            height: "51.25rem",
            width: this.props.width ?? "13.75rem",
            borderRadius: "0.635rem"
        };

        const RiskTowerHeaderStyle = {
            height: "4.375rem",
            width: "100%",
            position: "relative"
        };

        const RiskTowerHeaderTextStyle = {
            paddingLeft: "0.75rem",
            paddingTop: "1.0625rem",
            display: "inline-flex"
        };

        const RiskTowerNumberTextStyle = {
            display: "inline-flex",
            position: "absolute",
            right: "0.75rem",
            top: "0.875rem"
        };

        // if the risk list is empty, is should be inactive
        if (!this.state.riskList.length) {
            RiskTowerStyle["backgroundColor"] = RiskTowerInactiveBGColor;
        } else {
            RiskTowerStyle["backgroundColor"] = RiskTowerActiveBGColor;
        }

        const onHover = () => {
            this.setState({ hover: true });
        };

        const onUnhover = () => {
            this.setState({ hover: false });
        };

        const riskTowerHoverEffectStyle = Object.assign({}, RiskTowerStyle, {
            boxShadow: mapping["Color_Risk_Tower_Hover_Shadow"]
        });
        return (
            <div
                data-test={"risk_tower"}
                style={
                    this.state.hover && this.state.riskList.length
                        ? riskTowerHoverEffectStyle
                        : RiskTowerStyle
                }
                onMouseEnter={onHover}
                onMouseLeave={onUnhover}
            >
                {/* Tower header */}
                <div style={RiskTowerHeaderStyle}>
                    {/* Tower title text */}
                    <div
                        style={Object.assign(
                            {},
                            RiskTowerHeaderTextStyle,
                            mapping["heading/h5/lightleft"]
                        )}
                    >
                        {this.props.category}
                    </div>

                    {/* Tower header total risk count */}
                    <div style={RiskTowerNumberTextStyle}>
                        <TotalRiskIcon
                            totalNumber={this.state.riskList.length}
                        />
                    </div>
                </div>

                <div
                    style={{
                        height: "46.25rem",
                        overflowX: "hidden",
                        overflowY: "scroll",
                        padding: "1px"
                    }}
                    className={"scrollbar_hidden"}
                >
                    {this.state.riskList.map(risk => {
                        return (
                            <div style={{ paddingBottom: "1.25rem" }}>
                                <ABCDemoRiskTile
                                    width={this.props.width}
                                    risk={risk}
                                    onHoverCallback={this.props.onHoverCallback}
                                    onUnhoverCallback={
                                        this.props.onUnhoverCallback
                                    }
                                    RiskTileClick={this.props.RiskTileClick}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export { ABCDemoRiskTower };
