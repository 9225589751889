import React from "react";
import { mapping } from "../style/mapping";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "@progress/kendo-theme-bootstrap/dist/all.css";

class DropDownListButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            value: this.props.value
        };
    }

    formUpdate = newValue => {
        if (this.props.onChange) {
            this.props.onChange({ value: newValue });
        }
    };

    componentDidMount() {
        if (this.props.onChange) {
            this.props.onChange({ value: this.state.value });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.populate_id !== this.props.populate_id) {
            this.setState({
                data: nextProps.data,
                // if the value is not inside the selection, no point to display that, reset to the 1st
                // option in the selection
                // if it is inside the selection, set it to props.value( this is because onChange function in the Form)
                // if not used in the Form, this function will not be triggered when state change
                value: nextProps.data.includes(nextProps.value)
                    ? nextProps.value
                    : nextProps.data[0]
            });

            this.formUpdate(nextProps.value);
        } else if (
            JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)
        ) {
            this.setState({
                value: nextProps.value ? nextProps.value : nextProps.data[0],
                data: nextProps.data
            });

            this.formUpdate(nextProps.value);
        } else if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value });

            this.formUpdate(nextProps.value);
        }
    }

    render() {
        const defaultDropDownButtonStyle = {
            width: "8.75rem",
            height: "2rem",
            backgroundColor: mapping["Color_Basic_Secondary"],
            border: "none",
            boxShadow: "none"
        };

        const fontStyle = {
            ...mapping["forms/input-small"]
        };

        const defaultItemStyle = {};

        var DropDownButtonStyle = this.props.DropDownButtonStyle
            ? this.props.DropDownButtonStyle
            : defaultDropDownButtonStyle;
        var ItemStyle = this.props.ItemStyle
            ? this.props.ItemStyle
            : defaultItemStyle;

        const valueRender = (element, value) => {
            if (!value) {
                return element;
            }

            const children = [
                <span key={2} style={fontStyle}>
                    {element.props.children}
                </span>
            ];

            return React.cloneElement(element, { ...element.props }, children);
        };

        const itemRender = (li, itemProps) => {
            let DisplayData = this.props.dataDisplayKey
                ? itemProps.dataItem[this.props.dataDisplayKey]
                : itemProps.dataItem;
            const index = this.props.dataDisplayKey
                ? itemProps.dataItem.id
                : itemProps.index;
            const itemChildren = <span id={index}>{DisplayData}</span>;

            return React.cloneElement(li, li.props, itemChildren);
        };

        const onChange = event => {
            //console.log(event);
            let value = this.props.dataDisplayKey
                ? event.value.id
                : event.value;

            this.setState({ value: event.value });
            if (this.props.CustomOnChange) {
                this.props.CustomOnChange(event);
            }

            this.formUpdate(value);
        };

        let DisplayValue = this.props.dataDisplayKey
            ? this.state.value[this.props.dataDisplayKey]
            : this.state.value;


        return (
            <div>
                {this.props.title && (
                    <span style={titleStyle}>{this.props.title}</span>
                )}

                <DropDownList
                    index={this.props.index}
                    data={this.props.data}
                    onChange={onChange}
                    value={DisplayValue}
                    valueRender={valueRender}
                    itemRender={itemRender}
                    style={{
                        ...defaultDropDownButtonStyle,
                        ...this.props.DropDownButtonStyle
                    }}
                    disabled={this.props.disabled}
                />
                <style>
                    {'.k-icon { width: 1em;height: 1em;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;font-size: 1rem;font-family: "WebComponentsIcons";font-style: normal;font-variant: normal;font-weight: normal;line-height: 1;speak: none;text-transform: none;text-decoration: none;display: inline-block;vertical-align: middle;}' +
                        ".k-dropdown .k-dropdown-wrap .k-input {height:100%; padding: 0.625rem; padding-right:0}"}
                </style>
            </div>
        );
    }
}

const titleStyle = {
    ...mapping["forms/label/1-default"],
    ...mapping["Form_Label_Padding"],
    display: "flex",
    paddingBottom: "9px",
    color: mapping["Color_Basic_Light"]
};

export { DropDownListButton };
