import React from "react";
import "hammerjs";
import {
    Chart,
    ChartArea,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartAxisDefaults,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartSeriesMarkers,
    ChartCategoryAxisLabels
} from "@progress/kendo-react-charts";
import ChartXAxis from "@progress/kendo-react-charts/dist/es/components/XAxis";
import ChartXAxisItem from "@progress/kendo-react-charts/dist/es/components/XAxisItem";
import ChartYAxis from "@progress/kendo-react-charts/dist/es/components/YAxis";
import ChartYAxisItem from "@progress/kendo-react-charts/dist/es/components/YAxisItem";
import ChartTitle from "@progress/kendo-react-charts/dist/es/components/Title";
import ChartLegend from "@progress/kendo-react-charts/dist/es/components/Legend";
import ChartTooltip from "@progress/kendo-react-charts/dist/es/components/Tooltip";
import ChartYAxisLabels from "@progress/kendo-react-charts/dist/es/components/y-axis-item/Labels";

class BarChart extends React.Component {
    render() {
        const seriesLabels = {
            visible: true, // Note that visible defaults to false
            padding: 3,
            font: "bold 1rem Arial, sans-serif",
            background: "transparent",
            color: "white"
        };

        let configKey = configKeyFunction(this.props.data[0]);

        return (
            <div>
                <Chart
                    style={{
                        height: this.props.height,
                        width: this.props.width
                    }}
                    onSeriesClick={event => {
                        this.props.onBarClick(event);
                    }}
                >
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            max={this.props.max}
                            labels={{ visible: false }}
                        />
                    </ChartValueAxis>
                    <ChartAxisDefaults
                        line={{ visible: false }}
                        majorGridLines={{ visible: false }}
                        minorGridLines={{ visible: false }}
                    />
                    <ChartArea
                        background={
                            this.props.chartBackgroundColor ?? "transparent"
                        }
                        margin={20}
                    />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            labels={{
                                font: "0.625rem sans-serif",
                                color: "white",
                                rotation: "auto"
                            }}
                        />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            gap={0.2}
                            data={this.props.data}
                            categoryField={configKey[0]}
                            field={configKey[1]}
                            colorField={configKey[2]}
                            type="column"
                            labels={seriesLabels}
                        />
                    </ChartSeries>
                </Chart>
            </div>
        );
    }
}

class AlertBarChart extends React.Component {
    render() {
        const seriesLabels = {
            visible: true, // Note that visible defaults to false
            padding: 3,
            font: "bold 1rem Arial, sans-serif",
            background: "transparent",
            color: "white"
        };

        let configKey = configKeyFunction(this.props.data[0]);

        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <Chart
                    style={{
                        height: this.props.height,
                        width: this.props.width
                    }}
                    onSeriesClick={event => {
                        this.props.onBarClick(event);
                    }}
                >
                    <ChartArea
                        background={"#00000050"}
                        margin={(20 / 16) * rem}
                    />
                    <ChartAxisDefaults
                        line={{ color: "white", visible: true }}
                        majorGridLines={{ color: "white", visible: false }}
                        minorGridLines={{ color: "white", visible: false }}
                    />
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            max={this.props.max}
                            labels={{
                                font: "0.625rem sans-serif",
                                color: "white",
                                rotation: "auto"
                            }}
                            title={{
                                font: "0.8125rem Roboto, sans-serif",
                                color: "white",
                                text: this.props.YAxisName
                            }}
                        />
                    </ChartValueAxis>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            labels={{
                                font: "0.625rem sans-serif",
                                color: "white",
                                rotation: "auto"
                            }}
                            title={{
                                font: "0.8125rem Roboto, sans-serif",
                                color: "white",
                                text: this.props.XAxisName
                            }}
                        >
                            <ChartCategoryAxisLabels
                                rotation={-90}
                                color={"white"}
                            />
                        </ChartCategoryAxisItem>
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            gap={this.props.gap ?? 0.2}
                            data={this.props.data}
                            categoryField={configKey[0]}
                            field={configKey[1]}
                            colorField={configKey[2]}
                            type="column"
                            // labels={seriesLabels}
                        />
                    </ChartSeries>
                </Chart>
            </div>
        );
    }
}

class StackBarChart extends React.Component {
    render() {
        const seriesLabels = {
            visible: true, // Note that visible defaults to false
            padding: 3,
            font: "bold 1rem Arial, sans-serif",
            background: "transparent",
            color: "white"
        };
        // console.log(this.props.data);
        let configKey = configKeyFunction(this.props.data[0][0]);

        return (
            <div>
                <Chart
                    style={{
                        height: this.props.height,
                        width: this.props.width
                    }}
                    onSeriesClick={event => {
                        this.props.onBarClick(event);
                    }}
                >
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            max={this.props.max}
                            labels={{ visible: false }}
                        />
                    </ChartValueAxis>
                    <ChartAxisDefaults
                        line={{ visible: false }}
                        majorGridLines={{ visible: false }}
                        minorGridLines={{ visible: false }}
                    />
                    <ChartArea background={"transparent"} margin={20} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            labels={{
                                font: "0.625rem sans-serif",
                                color: "white",
                                rotation: "auto"
                            }}
                        />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            gap={0.2}
                            data={this.props.data[0]}
                            categoryField={configKey[0]}
                            field={configKey[1]}
                            colorField={configKey[2]}
                            type="column"
                            labels={seriesLabels}
                            stack={this.props.stack ?? { type: "100%" }}
                        />
                        <ChartSeriesItem
                            gap={0.2}
                            data={this.props.data[1]}
                            categoryField={configKey[0]}
                            field={configKey[1]}
                            colorField={configKey[2]}
                            type="column"
                        />
                    </ChartSeries>
                </Chart>
            </div>
        );
    }
}

class LineChart extends React.Component {
    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        const seriesLabels = {
            visible: true, // Note that visible defaults to false
            padding: 3,
            font: "bold 1rem Arial, sans-serif",
            background: "transparent",
            color: "white"
        };

        let configKey = configKeyFunction(this.props.data[0]);

        return (
            <div>
                <Chart
                    style={{
                        height: this.props.height,
                        width: this.props.width
                    }}
                    onSeriesClick={event => {
                        this.props.onBarClick(event);
                    }}
                >
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            max={this.props.max}
                            labels={{ visible: false }}
                        />
                    </ChartValueAxis>
                    <ChartAxisDefaults
                        line={{ visible: false }}
                        majorGridLines={{ visible: false }}
                        minorGridLines={{ visible: false }}
                    />
                    <ChartArea background={"transparent"} margin={20} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            labels={{
                                font: "0.625rem sans-serif",
                                color: "white",
                                rotation: "auto"
                            }}
                        />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            size={0}
                            data={this.props.data}
                            categoryField={configKey[0]}
                            field={configKey[1]}
                            colorField={configKey[2]}
                            type="line"
                            labels={seriesLabels}
                        >
                            <ChartSeriesMarkers
                                background={"#00000000"}
                                border={{ width: (8 / 16) * rem }}
                            />
                        </ChartSeriesItem>
                    </ChartSeries>
                </Chart>
            </div>
        );
    }
}

class BubbleChart extends React.Component {
    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <Chart
                    style={{
                        height: this.props.height,
                        width: this.props.width
                    }}
                    onSeriesClick={event => {
                        this.props.onBubbleClick(event);
                    }}
                >
                    <ChartAxisDefaults
                        line={{ color: "white", visible: true }}
                        majorGridLines={{ color: "white", visible: false }}
                        minorGridLines={{ color: "white", visible: false }}
                    />
                    <ChartArea
                        background={
                            this.props.chartBackgroundColor ?? "#00000050"
                        }
                        margin={(20 / 16) * rem}
                    />
                    <ChartSeries>
                        <ChartSeriesItem
                            type="bubble"
                            xField="x"
                            yField="y"
                            sizeField="size"
                            categoryField="category"
                            colorField="color"
                            data={this.props.data}
                        />
                    </ChartSeries>
                    <ChartXAxis>
                        <ChartXAxisItem
                            //plotBands={xPlotBands}
                            title={{
                                font: "0.8125rem Roboto, sans-serif",
                                color: "white",
                                text: this.props.XAxisName
                            }}
                            labels={{
                                color: "white",
                                format: "{0:N0}",
                                skip: 1,
                                rotation: "auto"
                            }}
                        />
                    </ChartXAxis>
                    <ChartYAxis>
                        <ChartYAxisItem
                            min={-0.8}
                            title={{
                                font: "0.8125rem Roboto, sans-serif",
                                color: "white",
                                text: this.props.YAxisName
                            }}
                            labels={{ color: "white", format: "{0:N0}" }}
                        />
                    </ChartYAxis>
                    <ChartLegend visible={true} />
                    <ChartTooltip format="{3}: {2:N0}" opacity={1} />
                </Chart>
            </div>
        );
    }
}

class ScatterChart extends React.Component {
    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        let markers = {
            size: e => {
                return e.dataItem.self ? (18 / 16) * rem : (12 / 16) * rem;
            },
            shape: "circle",
            background: e => {
                return e.dataItem.color;
            },
            border: e => {
                if (e.dataItem.self) {
                    return {
                        color: e.dataItem.borderColor ?? "#F04040",
                        width: "0.1875rem"
                    };
                }
                return {
                    color: e.dataItem.borderColor ?? "#00D3ED",
                    width: "0.125rem"
                };
            },
            fill: { Opacity: "0" }
        };
        return (
            <div>
                <Chart
                    style={{
                        height: this.props.height,
                        width: this.props.width
                    }}
                    onSeriesClick={event => {
                        this.props.onPointClick(event);
                    }}
                >
                    <ChartAxisDefaults
                        line={{ color: "white", visible: true }}
                        majorGridLines={{ color: "white", visible: false }}
                        minorGridLines={{ color: "white", visible: false }}
                    />
                    <ChartArea
                        background={
                            this.props.chartBackgroundColor ?? "#00000050"
                        }
                        margin={(20 / 16) * rem}
                    />
                    <ChartSeries>
                        <ChartSeriesItem
                            type="scatter"
                            data={this.props.data}
                            xField="x"
                            yField="y"
                            markers={markers}
                        />
                    </ChartSeries>

                    <ChartXAxis>
                        <ChartXAxisItem
                            axisCrossingValue={this.props.Xmin}
                            min={this.props.Xmin}
                            title={{
                                font: "0.8125rem Roboto, sans-serif",
                                color: "white",
                                text: this.props.XAxisName
                            }}
                            labels={{ color: "white", format: "{0:N0}" }}
                        />
                    </ChartXAxis>
                    <ChartYAxis>
                        <ChartYAxisItem
                            axisCrossingValue={this.props.Ymin}
                            min={this.props.Ymin}
                            title={{
                                font: "0.8125rem Roboto, sans-serif",
                                color: "white",
                                text: this.props.YAxisName
                            }}
                            labels={{ color: "white", format: "{0:N0}" }}
                        />
                    </ChartYAxis>
                    <ChartTooltip
                        render={e => {
                            let pointData = e.point.dataItem;
                            return (
                                pointData.name +
                                " : " +
                                pointData.tooltipData.toString().slice(0, 5)
                            );
                        }}
                        background={"#00D3ED"}
                        opacity={1}
                    />
                </Chart>
            </div>
        );
    }
}

class BoxPlotChart extends React.Component {
    render() {
        return (
            <Chart
                style={{
                    height: this.props.height,
                    width: this.props.width
                }}
                onSeriesClick={event => {
                    this.props.onBoxClick(event);
                }}
            >
                <ChartAxisDefaults
                    line={{ color: "white", visible: true }}
                    majorGridLines={{ color: "grey", visible: true }}
                    minorGridLines={{ color: "white", visible: false }}
                />
                <ChartArea
                    background={this.props.chartBackgroundColor ?? "#00000000"}
                    margin={5}
                />

                <ChartValueAxis>
                    <ChartValueAxisItem
                        max={this.props.max}
                        labels={{
                            visible: true,
                            color: "white",
                            font: "0.5rem Roboto, sans-serif"
                        }}
                    />
                </ChartValueAxis>
                <ChartSeries color={"#A2F8F780"}>
                    <ChartSeriesItem
                        color={"#A2F8F780"}
                        line={{
                            width: 20,
                            color: "#A2F8F780"
                        }}
                        //mean={{ visible: false }}
                        median={{ color: "blue" }}
                        extremes={{
                            border: { color: "red" },
                            size: 10,
                            type: "circle"
                        }}
                        outliers={{
                            border: { color: "red" },
                            size: 10,
                            type: "circle"
                        }}
                        type="boxPlot"
                        lowerField="lower"
                        q1Field="q1"
                        medianField="median"
                        q3Field="q3"
                        upperField="upper"
                        outliersField="outliers"
                        //meanField="mean"
                        categoryField="category"
                        data={this.props.data}
                    />
                </ChartSeries>
            </Chart>
        );
    }
}

class HorizontalBarChart extends React.Component {
    render() {
        const seriesLabels = {
            visible: true, // Note that visible defaults to false
            padding: 3,
            font: "bold 1rem Arial, sans-serif",
            background: "transparent",
            color: "white"
        };

        let configKey = Object.keys(this.props.data[0]);

        return (
            <div>
                <Chart
                    style={{ height: this.props.height }}
                    onSeriesClick={event => {
                        this.props.onBarClick(event);
                    }}
                >
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            max={this.props.max}
                            labels={{ visible: false }}
                        />
                    </ChartValueAxis>
                    <ChartAxisDefaults
                        line={{ visible: false }}
                        majorGridLines={{ visible: false }}
                        minorGridLines={{ visible: false }}
                    />
                    <ChartArea background={"transparent"} margin={20} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            labels={{
                                font: "0.625rem sans-serif",
                                color: "white",
                                rotation: "auto"
                            }}
                        />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            gap={this.props.gap}
                            data={this.props.data}
                            categoryField={configKey[0]}
                            field={configKey[1]}
                            colorField={configKey[2]}
                            type="bar"
                            labels={seriesLabels}
                        />
                    </ChartSeries>
                </Chart>
            </div>
        );
    }
}

const configKeyFunction = dataSample => {
    let configKey = ["", "", ""];
    Object.keys(dataSample).map((key, index) => {
        if (index > 2) {
            return;
        }
        if (Number.isFinite(dataSample[key])) {
            configKey[1] = key;
        } else {
            const s = new Option().style;
            s.color = dataSample[key];
            if (s.color !== "" && key.toLowerCase().includes("color")) {
                configKey[2] = key;
            } else {
                configKey[0] = key;
            }
        }
    });
    return configKey;
};

export {
    BarChart,
    StackBarChart,
    LineChart,
    BubbleChart,
    ScatterChart,
    BoxPlotChart,
    HorizontalBarChart,
    AlertBarChart
};
