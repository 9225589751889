import React from "react";
import { mapping } from "../../style/mapping";
import { ButtonSolid, IconButtonSolid } from "../button/Button";
import { NonValidationTextInput } from "../Field";
import { CustomizedCheckBox } from "../CheckBox";
import { RadioButtonGroup } from "../RadioButtonGroup";
import { FormatDate, FindDisplayName } from "../util";
import moment from "moment";
import { IconSVG } from "../Icon";
import { UploadBox } from "../TaskForm";
import { navigateToUrl } from "single-spa";
import { DropDownListButton } from "../DropDownList";

class ViewTaskManagementTool extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taskCardData: JSON.parse(JSON.stringify(this.props.taskCardData))
        };
        // this.state.taskCardData = this.props.taskCardData; // :o shallow copy
        this.viewHistory = this.props.viewHistory;
    }
    render() {
        let taskCardData = this.state.taskCardData;
        taskCardData.meta_data = JSON.parse(taskCardData.demo_meta_data);
        let comments = taskCardData.comments;
        comments = comments.sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1));
        // console.log(comments);
        // console.log(taskCardData);
        // console.log("Current User: ", this.props.currentUser);
        // console.log(
        //     "Check selected: ",
        //     taskCardData.actions[0].actionContent[0].selected
        // );
        // console.log(
        //     "Check answer: ",
        //     taskCardData.actions[0].actionContent[0].answer
        // );
        console.log(taskCardData);
        return (
            <div style={RiskCreationFormWrapStyle}>
                <div style={RiskCreationFormStyle}>
                    <div
                        style={{
                            width: "74rem",
                            // height: "fit-content",
                            height: "52rem",
                            backgroundColor: "rgba(0,0,0,0.4)",
                            padding: "1.2rem"
                        }}
                    >
                        <div
                            style={{
                                display: "inline-flex",
                                justifyContent: "space-between",
                                width: "100%"
                            }}
                        >
                            <div style={{ ...mapping["heading/h5/lightleft"] }}>
                                Task View
                            </div>
                            {taskCardData.alert_id && (
                                <ButtonSolid
                                    name={"Go to alert"}
                                    color={"Danger"}
                                    height={"1.9375rem"}
                                    width={"6.25rem"}
                                    clickEvent={() => {
                                        navigateToUrl(
                                            "alert-management/" +
                                                taskCardData.alert_id +
                                                "/"
                                        );
                                    }}
                                />
                            )}
                        </div>
                        <div>
                            {/* DETAILS BLOCK (LEFT) ===========================================================================*/}
                            <div
                                className={"scrollbar"}
                                style={detailBlockStyle}
                            >
                                <FieldNonEdit
                                    title={""}
                                    value={taskCardData.title}
                                    width={"34rem"}
                                />
                                {/*<FieldNonEdit*/}
                                {/*    title={*/}
                                {/*        taskCardData.category === "pending" &&*/}
                                {/*        taskCardData.type === "task"*/}
                                {/*            ? "To"*/}
                                {/*            : "From"*/}
                                {/*    }*/}
                                {/*    value={*/}
                                {/*        taskCardData.category === "pending" &&*/}
                                {/*        taskCardData.type === "task"*/}
                                {/*            ? taskCardData.to*/}
                                {/*            : taskCardData.from*/}
                                {/*    }*/}
                                {/*    width={"16rem"}*/}
                                {/*/>*/}
                                <FieldNonEdit
                                    title={"Customer ID"}
                                    value={taskCardData.meta_data.customer_id}
                                    width={"16rem"}
                                />
                                <FieldNonEdit
                                    title={"Due Date"}
                                    value={taskCardData.dueDate}
                                    width={"16rem"}
                                    marginLeft={"2rem"}
                                />
                                <FieldNonEdit
                                    title={"Client Name"}
                                    value={taskCardData.meta_data.client_name}
                                    width={"16rem"}
                                />
                                <FieldNonEdit
                                    title={"RM Name"}
                                    value={taskCardData.meta_data.rm_name}
                                    width={"16rem"}
                                    marginLeft={"2rem"}
                                />
                                <FieldNonEdit
                                    title={"Business Unit"}
                                    value={taskCardData.meta_data.business_unit}
                                    width={"16rem"}
                                />
                                <FieldNonEdit
                                    title={"Risk rating"}
                                    value={taskCardData.meta_data.risk_rating}
                                    width={"16rem"}
                                    marginLeft={"2rem"}
                                />
                                <FieldNonEdit
                                    title={"Reviewer Name"}
                                    value={taskCardData.meta_data.reviewer_name}
                                    width={"16rem"}
                                />
                                <FieldNonEdit
                                    title={"QA Reviewer Name"}
                                    value={
                                        taskCardData.meta_data.qa_reviewer_name
                                    }
                                    width={"16rem"}
                                    marginLeft={"2rem"}
                                />

                                <FieldTitle value={"Status"} width={"34rem"} />
                                <DropDownListButton
                                    value={taskCardData.status}
                                    data={[
                                        "Open",
                                        "Review in Progress",
                                        "Pending QA review",
                                        "Pending RM review",
                                        "Completed - no issues"
                                    ]}
                                    DropDownButtonStyle={{ width: "16rem" }}
                                    onChange={event =>
                                        this.handleStatusChange(event.value)
                                    }
                                    // disabled={props.disabled}
                                />
                                {/*<FieldNonEdit*/}
                                {/*    title={"Status"}*/}
                                {/*    value={taskCardData.status}*/}
                                {/*    width={"16rem"}*/}
                                {/*/>*/}
                                {/*{!this.props.disabled && (*/}
                                {/*    <div>*/}
                                {/*        <FieldTitle*/}
                                {/*            value={"Description"}*/}
                                {/*            width={"34rem"}*/}
                                {/*        />*/}
                                {/*        <MultiTextInput*/}
                                {/*            style={"dark"}*/}
                                {/*            height={"4.75rem"}*/}
                                {/*            value={taskCardData.description}*/}
                                {/*            onChange={event => {*/}
                                {/*                this.handleDescriptionChange(*/}
                                {/*                    event.value*/}
                                {/*                );*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                {/*{this.props.disabled && (*/}
                                {/*    <FieldNonEdit*/}
                                {/*        title={"Description"}*/}
                                {/*        value={taskCardData.description}*/}
                                {/*        width={"34rem"}*/}
                                {/*        height={"auto"}*/}
                                {/*    />*/}
                                {/*)}*/}
                                <div style={{ width: "34rem" }}>
                                    <UploadBox
                                        handleAttachmentChange={
                                            this.handleTaskUploadNewFiles
                                        }
                                        //disabled={this.props.disabled}
                                        uploadingFile={taskCardData.attachments}
                                        handleAttachmentDownload={
                                            this.props
                                                .handleTaskAttachmentDownload
                                        }
                                        handleRemoveUploadFile={
                                            //remove task attachment
                                            this.handleTaskRemoveAttachment
                                        }
                                        disabled={
                                            this.props.taskAttachmentDisabled
                                        }
                                    />
                                </div>
                                <div>
                                    {taskCardData.actions
                                        .sort((a, b) => {
                                            return a.actionID > b.actionID
                                                ? 1
                                                : -1;
                                        })
                                        .map((action, index) => {
                                            if (
                                                action.actionType ===
                                                "Text Field"
                                            ) {
                                                return (
                                                    <ViewTextField
                                                        disabled={
                                                            this.props.disabled
                                                        }
                                                        title={
                                                            action
                                                                .actionContent[0]
                                                                .question
                                                        }
                                                        value={
                                                            action
                                                                .actionContent[0]
                                                                .answer
                                                        }
                                                        onChange={event => {
                                                            this.handleTextFieldChange(
                                                                index,
                                                                event.value
                                                            );
                                                        }}
                                                    />
                                                );
                                            } else if (
                                                action.actionType ===
                                                "Upload Request"
                                            ) {
                                                console.log(action);
                                                return (
                                                    <div>
                                                        <ViewUploadRequest
                                                            ID={action.actionID}
                                                            disabled={
                                                                this.props
                                                                    .disabled
                                                            }
                                                            title={
                                                                action
                                                                    .actionContent[0]
                                                                    .question
                                                            }
                                                            uploadingFile={
                                                                action
                                                                    .actionContent[0]
                                                                    .attachments
                                                            }
                                                            handleAttachmentChange={e => {
                                                                console.log(
                                                                    action.actionID
                                                                );
                                                                this.handleActionUploadNewFiles(
                                                                    action.actionID,
                                                                    e
                                                                );
                                                            }}
                                                            handleTaskActionAttachmentDownload={
                                                                this.props
                                                                    .handleTaskActionAttachmentDownload
                                                            }
                                                            handleRemoveUploadFile={index => {
                                                                this.handleActionRemoveAttachment(
                                                                    index,
                                                                    action.actionID
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            } else if (
                                                action.actionType ===
                                                    "Multiple Choice" &&
                                                action.actionContent[0].type ===
                                                    "single"
                                            ) {
                                                console.log(action);
                                                return (
                                                    <div>
                                                        <ViewMultipleChoiceSingle
                                                            disabled={
                                                                this.props
                                                                    .disabled
                                                            }
                                                            question={
                                                                action
                                                                    .actionContent[0]
                                                                    .question
                                                            }
                                                            radioData={
                                                                action
                                                                    .actionContent[0]
                                                                    .options
                                                            }
                                                            retrieveValue={
                                                                action
                                                                    .actionContent[0]
                                                                    .selected[0]
                                                            }
                                                            onRadioCustomChange={event => {
                                                                this.handleRadioCustomChange(
                                                                    index,
                                                                    event.value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            } else if (
                                                action.actionType ===
                                                    "Multiple Choice" &&
                                                action.actionContent[0].type ===
                                                    "multi"
                                            ) {
                                                return (
                                                    <ViewMultipleChoiceMulti
                                                        disabled={
                                                            this.props.disabled
                                                        }
                                                        question={
                                                            action
                                                                .actionContent[0]
                                                                .question
                                                        }
                                                        index={index}
                                                        selectedOptions={
                                                            action
                                                                .actionContent[0]
                                                                .selected
                                                        }
                                                        checkOptions={
                                                            action
                                                                .actionContent[0]
                                                                .options
                                                        }
                                                        handleCheckBoxChange={
                                                            this
                                                                .handleCheckBoxChange
                                                        }
                                                    />
                                                );
                                            }
                                        })}
                                </div>
                            </div>
                            {/* COMMENT BLOCK (RIGHT) ==========================================================================*/}
                            <div
                                style={{
                                    width: "34rem",
                                    marginTop: "1rem",
                                    float: "left"
                                }}
                            >
                                <FieldTitle value={"Comments"} />
                                <div
                                    className={"scrollbar"}
                                    style={{
                                        width: "100%",
                                        backgroundColor: "rgba(0,0,0,0.4)",
                                        height: this.props.viewHistory
                                            ? "37.3rem"
                                            : "32.3rem",
                                        float: "left",
                                        overflowY: "auto",
                                        padding: "1rem",
                                        display: "flex",
                                        flexDirection: "column-reverse"
                                    }}
                                >
                                    <div>
                                        {comments.map(comment => {
                                            let displayName = "";
                                            if (
                                                this.props.userList.length !== 0
                                            ) {
                                                displayName = FindDisplayName(
                                                    this.props.userList,
                                                    comment.userName
                                                );
                                            } else {
                                                displayName =
                                                    comment.userDisplay;
                                            }
                                            return (
                                                <CommentBox
                                                    commentUsername={
                                                        comment.userName
                                                    }
                                                    commentDisplayName={
                                                        displayName
                                                    }
                                                    currentUser={
                                                        this.props.currentUser
                                                    }
                                                    commentText={
                                                        comment.commentText
                                                    }
                                                    dateTime={comment.dateTime}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                                {this.props.viewHistory === false && (
                                    <div>
                                        <MultiTextInput
                                            style={"light"}
                                            placeholder={"Type a new message"}
                                            height={"5rem"}
                                            width={"31.5rem"}
                                            value={taskCardData.commentDraft}
                                            onChange={event => {
                                                this.handleCommentChange(
                                                    event.value
                                                );
                                            }}
                                            // enterEvent={this.handleSendComment}
                                        />
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                height: "5rem",
                                                width: "2.5rem",
                                                float: "left"
                                            }}
                                        >
                                            <IconButtonSolid
                                                icon={
                                                    <IconSVG
                                                        name={"send"}
                                                        color={"white"}
                                                    />
                                                }
                                                height={"5rem"}
                                                width={"2.5rem"}
                                                size={"Small"}
                                                clickEvent={() => {
                                                    this.handleSendComment(
                                                        this.props.currentUser
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div style={buttonWrapper}>
                            <ButtonSolid
                                name={"Cancel"}
                                height={"2.375rem"}
                                width={"7.5rem"}
                                size={"Small"}
                                clickEvent={this.props.cancelFunction}
                            />
                            <div style={{ display: "inline-flex" }}>
                                <ButtonSolid
                                    name={"Save"}
                                    height={"2.375rem"}
                                    width={"7.5rem"}
                                    size={"Small"}
                                    clickEvent={() => {
                                        this.props.handleViewTaskSave(
                                            this.state.taskCardData
                                        );
                                    }}
                                />
                                <div style={{ width: "0.625rem" }} />
                                {taskCardData.from !==
                                    this.props.currentUser.username && (
                                    <ButtonSolid
                                        name={"Submit"}
                                        height={"2.375rem"}
                                        width={"7.5rem"}
                                        size={"Small"}
                                        clickEvent={() => {
                                            this.props.handleViewTaskSave(
                                                this.state.taskCardData
                                            );
                                            this.props.handleTaskUserSwitch(
                                                this.state.taskCardData.id
                                            );
                                        }}
                                    />
                                )}
                                <div style={{ width: "10px" }} />
                                {/*eslint-disable*/}
                                {taskCardData.status !== "closed" && taskCardData.creator === this.props.currentUser.username && (
                                    <ButtonSolid
                                        name={"Mark as Completed"}
                                        height={"2.375rem"}
                                        width={"9.375rem"}
                                        size={"Small"}
                                        clickEvent={() => {
                                            this.props.handleTaskClose(
                                                this.state.taskCardData.id
                                            );
                                        }}
                                    />
                                )}
                                {/*eslint-enable*/}
                                {taskCardData.status === "closed" && (
                                    <ButtonSolid
                                        name={"Mark as Open"}
                                        height={"2.375rem"}
                                        width={"9.375rem"}
                                        size={"Small"}
                                        clickEvent={() => {
                                            this.props.handleTaskOpen(
                                                this.state.taskCardData.id
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    handleDescriptionChange = value => {
        let taskCardData = this.state.taskCardData;
        taskCardData.description = value;
        this.setState({ taskCardData: taskCardData });
    };
    handleStatusChange = value => {
        let taskCardData = this.state.taskCardData;
        taskCardData.status = value;
        this.setState({ taskCardData: taskCardData });
    };
    handleTextFieldChange = (index, value) => {
        let taskCardData = this.state.taskCardData;
        taskCardData.actions[index].actionContent[0].answer = value;
        this.setState({ taskCardData: taskCardData });
    };
    handleRadioCustomChange = (index, value) => {
        let taskCardData = this.state.taskCardData;
        taskCardData.actions[index].actionContent[0].selected = value;
        this.setState({ taskCardData: taskCardData });
    };
    handleCommentChange = value => {
        let taskCardData = this.state.taskCardData;
        taskCardData.commentDraft = value;
        this.setState({ taskCardData: taskCardData });
        console.log("should be changed:", this.state.taskCardData.commentDraft);
    };
    handleSendComment = currentUser => {
        let taskCardData = this.state.taskCardData;
        let commentText = taskCardData.commentDraft;
        let dateTime = Date();
        if (commentText !== "") {
            taskCardData.comments.push({
                commentText: commentText,
                dateTime: dateTime,
                userDisplay: currentUser.display_name,
                userName: currentUser.username
            });
            taskCardData.commentDraft = "";
            this.setState({ taskCardData: taskCardData });
            console.log(this.state.taskCardData.comments);
        }
    };
    handleCheckBoxChange = (index, check, option) => {
        let taskCardData = this.state.taskCardData;
        if (check) {
            taskCardData.actions[index].actionContent[0].selected.push(option);
            this.setState({ taskCardData: taskCardData });
        } else {
            let uncheckedIndex = taskCardData.actions[
                index
            ].actionContent[0].selected.indexOf(option);
            taskCardData.actions[index].actionContent[0].selected.splice(
                uncheckedIndex,
                1
            );
            this.setState({ taskCardData: taskCardData });
        }
    };
    handleActionUploadNewFiles = (action_id, e) => {
        console.log(action_id, e);
        let taskCardData = this.state.taskCardData;
        let action = taskCardData.actions.filter(action => {
            return action.actionID === action_id;
        })[0];
        let uploadingFile = action.newAttchments ? action.newAttchments : [];
        let file;
        if (e.target) {
            file = e.target.files[0];
            if (file) {
                uploadingFile.push({
                    attachment_file: file,
                    name: file.name,
                    content_type: file.type
                });
            }
        } else {
            if (e[0] !== undefined) {
                //console.log(e[0]);
                file = e[0];
                uploadingFile.push({
                    attachment_file: file,
                    name: file.name,
                    content_type: file.type
                });
            }
        }
        action.newAttchments = uploadingFile;
        action.actionContent[0].attachments.push(file);
        this.setState({ taskCardData: taskCardData });
    };
    handleTaskUploadNewFiles = e => {
        let taskCardData = this.state.taskCardData;
        let uploadingFile = taskCardData.newAttchments
            ? taskCardData.newAttchments
            : [];
        let file;
        if (e.target) {
            file = e.target.files[0];
            if (file) {
                uploadingFile.push({
                    attachment_file: file,
                    name: file.name,
                    content_type: file.type
                });
            }
        } else {
            if (e[0] !== undefined) {
                //console.log(e[0]);
                file = e[0];
                uploadingFile.push({
                    attachment_file: file,
                    name: file.name,
                    content_type: file.type
                });
            }
        }
        taskCardData.newAttchments = uploadingFile;
        taskCardData.attachments.push(file);
        console.log(uploadingFile);
        this.setState({ taskCardData: taskCardData });
    };

    handleTaskRemoveAttachment = index => {
        let taskCardData = this.state.taskCardData;
        let removeTaskAttachmentsID = taskCardData.removeTaskAttachmentID;
        let uploadingFile = taskCardData.attachments;
        let newUploadingFile = taskCardData.newAttchments;
        let offset =
            uploadingFile.lengh -
            (newUploadingFile === undefined ? 0 : newUploadingFile.length);
        if (uploadingFile[index].id) {
            removeTaskAttachmentsID.push(uploadingFile[index].id);
        }
        if (newUploadingFile) {
            newUploadingFile.splice(index - offset, 1);
        }
        uploadingFile.splice(index, 1);
        console.log(removeTaskAttachmentsID);
        this.setState({ taskCardData: taskCardData });
    };
    handleActionRemoveAttachment = (index, action_id) => {
        let taskCardData = this.state.taskCardData;
        let action = taskCardData.actions.filter(action => {
            return action.actionID === action_id;
        })[0];

        let removeActionAttachmentsID =
            action.actionContent[0].removeActionAttachmentID;
        let uploadingFile = action.actionContent[0].attachments;
        let newUploadingFile = action.actionContent[0].newAttchments;

        let offset =
            uploadingFile.length -
            (newUploadingFile === undefined ? 0 : newUploadingFile.length);

        if (uploadingFile[index].id !== undefined) {
            removeActionAttachmentsID.push(uploadingFile[index].id);
        }
        if (newUploadingFile) {
            newUploadingFile.splice(index - offset, 1);
        }
        uploadingFile.splice(index, 1);
        console.log(removeActionAttachmentsID);
        this.setState({ taskCardData: taskCardData });
    };
}

const RiskCreationFormStyle = {
    position: "relative",
    left: "-50%"
};
const RiskCreationFormWrapStyle = {
    position: "absolute",
    left: "50%",
    top: "13.3rem"
};
const FieldTitleStyle = {
    ...mapping["forms/label/1-default"]
};
const FieldTitle = props => {
    return (
        <div
            style={{
                marginTop: props.marginTop ? props.marginTop : "1rem",
                float: props.float ? props.float : "left",
                width: props.width ? props.width : "34rem",
                height: props.height ? props.height : "auto"
            }}
        >
            <div style={FieldTitleStyle}>{props.value}</div>
        </div>
    );
};
const FieldSubtitle = props => {
    return (
        <div
            style={{
                float: "left",
                width: props.width ? props.width : "34rem"
            }}
        >
            <div style={{ ...FieldTitleStyle, color: "#818181" }}>
                {props.value}
            </div>
        </div>
    );
};
const FieldNonEdit = props => {
    return (
        <div
            style={{
                width: props.width,
                float: "left",
                marginLeft: props.marginLeft ? props.marginLeft : "0",
                marginBottom: props.marginBottom ? props.marginBottom : "0.5rem"
            }}
        >
            <FieldTitle value={props.title} width={props.width} />
            <div
                style={{
                    height: props.height ? props.height : "2.25rem",
                    minHeight: "2.25rem",
                    width: props.width,
                    borderWidth: 0,
                    backgroundColor: "rgba(0,0,0,0.85)",
                    border: "none !important",
                    color: mapping["Color_Basic_Light"],
                    padding: "0.3125rem",
                    resize: "none",
                    overflow: "hidden",
                    paddingLeft: mapping["Field_padding_Horizontal"],
                    paddingRight: mapping["Field_padding_Horizontal"],
                    borderRadius: 0,
                    outline: "none"
                }}
            >
                {props.value}
            </div>
        </div>
    );
};
const InputFieldStyleWhite = {
    height: "2.25rem",
    width: "100%",
    borderWidth: 0,
    backgroundColor: "White",
    border: "none !important",
    color: mapping["Color_Basic_Dark"],
    padding: "0.25rem",
    resize: "none",
    overflow: "hidden",
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    borderRadius: 0,
    outline: "none"
};
const InputFieldStyle = {
    height: "2.25rem",
    width: "34rem",
    borderWidth: 0,
    backgroundColor: mapping["Color_Form_00_Field_Solid"],
    border: "none !important",
    color: mapping["Color_Basic_Light"],
    padding: "0.25rem",
    resize: "none",
    overflow: "hidden",
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    borderRadius: 0,
    outline: "none"
};
const MultiTextInput = props => {
    const lightStyle = {
        ...InputFieldStyleWhite,
        height: props.height ? props.height : "4.6875rem",
        width: props.width ? props.width : "34rem",
        float: "left"
    };
    const otherStyle = {
        ...InputFieldStyle,
        height: props.height ? props.height : "4.6875rem",
        width: props.width ? props.width : "34rem",
        float: "left"
    };
    return (
        <NonValidationTextInput
            placeholder={props.placeholder}
            rows={2}
            changerow={() => {}}
            minrows={2}
            maxrows={5}
            lineheight={22}
            fieldstyle={props.style === "light" ? lightStyle : otherStyle}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            // onKeyPress={event => {
            //     if (event.key === "Enter" && props.enterEvent) {
            //         props.enterEvent();
            //     }
            // }}
        />
    );
};
const ViewActionStyle = {
    float: "left",
    width: "34rem",
    height: "auto",
    marginTop: "2rem"
};
const ViewTextField = props => {
    if (props.disabled) {
        return (
            <div style={ViewActionStyle}>
                <FieldNonEdit
                    title={props.title}
                    value={props.value}
                    width={"34rem"}
                    height={"auto"}
                />
            </div>
        );
    }
    {
        return (
            <div style={ViewActionStyle}>
                <FieldTitle value={props.title} width={"34rem"} />
                <MultiTextInput
                    style={"light"}
                    value={props.value}
                    onChange={props.onChange}
                />
            </div>
        );
    }
};
const ViewUploadRequest = props => {
    return (
        <div style={ViewActionStyle}>
            <FieldTitle
                value={"Please upload supporting documents. "}
                width={"34rem"}
            />
            <FieldSubtitle value={props.title} width={"34rem"} />
            <UploadBox
                ID={props.ID}
                handleAttachmentChange={props.handleAttachmentChange}
                //disabled={this.props.disabled}
                uploadingFile={props.uploadingFile}
                handleAttachmentDownload={
                    props.handleTaskActionAttachmentDownload
                }
                handleRemoveUploadFile={props.handleRemoveUploadFile}
                disabled={props.disabled}
            />
        </div>
    );
};
const ViewMultipleChoiceSingle = props => {
    let radioData = [];
    {
        props.radioData.map(option => {
            radioData.push({
                label: option,
                value: option,
                className: "task blue"
            });
        });
    }
    if (radioData.length === 0) {
        console.log(
            "there is error for MCQ test, please check ViewTask.js line 561!"
        );
        return <div />;
    }
    return (
        <div style={ViewActionStyle}>
            <FieldTitle value={props.question} width={"34rem"} />
            <div
                style={{
                    float: "left",
                    marginTop: "0.3rem",
                    marginLeft: "0.7rem"
                }}
            >
                <RadioButtonGroup
                    data={radioData}
                    defaultValue={
                        props.retrieveValue
                            ? props.retrieveValue
                            : "reflect nothing selected"
                    }
                    layout={"vertical"}
                    CustomOnChange={props.onRadioCustomChange}
                    disabled={props.disabled}
                />
            </div>
        </div>
    );
};
const ViewMultipleChoiceMulti = props => {
    return (
        <div style={ViewActionStyle}>
            <FieldTitle value={props.question} width={"34rem"} />
            <div>
                {props.checkOptions.map(option => {
                    return (
                        <div
                            style={{
                                width: "34rem",
                                float: "left",
                                height: "2.2rem"
                            }}
                        >
                            <CustomizedCheckBox
                                value={
                                    props.selectedOptions.indexOf(option) > -1
                                }
                                onCheckChange={event => {
                                    props.handleCheckBoxChange(
                                        props.index,
                                        event.value,
                                        option
                                    );
                                }}
                                disabled={props.disabled}
                                checkBoxDivStyle={{
                                    marginLeft: "0.5rem",
                                    width: "1.5rem",
                                    float: "left"
                                }}
                            />
                            <div style={CheckBoxLabelStyle}>{option}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
const CheckBoxLabelStyle = {
    ...mapping["forms/label/1-default"],
    color: "white",
    padding: "0.3rem",
    paddingLeft: "0.3rem",
    paddingRight: "1.5rem",
    height: "2.2rem"
};
const buttonWrapper = {
    display: "inline-flex",
    paddingTop: "4rem",
    width: "100%",
    justifyContent: "space-between"
};
const CommentBox = props => {
    let dateTimeTime = FormatDate(props.dateTime, "hh:mm");
    let dateTimeDate = FormatDate(props.dateTime, "DD MMM YY");
    let todayDate = FormatDate(Date(), "DD MMM YY");
    let yestDate = FormatDate(moment().subtract(1, "days"), "DD MMM YY");
    if (dateTimeDate === todayDate) {
        dateTimeDate = "Today";
    } else if (dateTimeDate === yestDate) {
        dateTimeDate = "Yesterday";
    }
    let currentUser = false;
    if (props.commentUsername === props.currentUser.username) {
        currentUser = true;
    }
    return (
        <div
            style={{
                width: "32rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: currentUser ? "flex-end" : "flex-start",
                alignItems: currentUser ? "flex-end" : "flex-start",
                marginTop: "0.5rem",
                marginBottom: "0.5rem"
            }}
        >
            <div
                style={{
                    height: "auto",
                    width: "25rem",
                    borderWidth: 0,
                    backgroundColor: mapping["Color_Form_00_Field_Solid"],
                    border: "none !important",
                    color: mapping["Color_Basic_Light"],
                    resize: "none",
                    overflow: "hidden",
                    paddingLeft: mapping["Field_padding_Horizontal"],
                    paddingRight: mapping["Field_padding_Horizontal"],
                    borderRadius: 0,
                    outline: "none"
                }}
            >
                <FieldTitle
                    value={currentUser ? "You" : props.commentDisplayName}
                    margintop="0rem"
                />
                <div
                    style={{
                        paddingLeft: "0.625rem",
                        paddingRight: "0.5rem",
                        width: "23rem",
                        marginBottom: "0.7rem"
                    }}
                >
                    {props.commentText}
                </div>
            </div>
            <div
                style={{
                    margin: "0.5rem",
                    color: mapping["Color_Basic_Light"],
                    fontSize: "0.75rem"
                }}
            >
                {dateTimeDate} {dateTimeTime}
            </div>
        </div>
    );
};
//
// const AttachmentWrapper = {
//     paddingLeft: "5%",
//     marginTop: "5px",
//     marginBottom: "5px",
//     display: "inline-flex",
//     alignItems: "center",
//     height: "40px",
//     width: "90%",
//     borderRadius: "24px",
//     backgroundColor: mapping["Color_BG_03_trans"]
// };
//
// const AttachmentStyle = {
//     textAlign: "left",
//     paddingLeft: "13px",
//     height: "22px",
//     width: "85%",
//     fontFamily: "Roboto",
//     fontSize: "16px",
//     letterSpacing: "0.2px",
//     lineHeight: "22px",
//     color: mapping["Color_Basic_Info"],
//     textDecoration: "underline"
// };
//
// const AttachmentDeleteButtonWrapperStyle = {
//     margin: "auto",
//     marginRight: "23px",
//     height: "24px",
//     width: "24px",
//     borderRadius: "3.1px",
//     backgroundColor: mapping["Color_Basic_Primary"]
// };

const detailBlockStyle = {
    width: "35.5rem",
    height: "40rem",
    marginRight: "2rem",
    marginTop: "1rem",
    float: "left",
    overflowY: "auto"
};
export { ViewTaskManagementTool };
