import { mapping } from "./mapping";

const taskManagementStyle = {
    buttonHeight: "2.0625rem",
    buttonWidth: "8.75rem",
    smallButtonWidth: "3.125rem",
    buttonWrapper: {
        display: "inline-flex",
        margin: "1.4375rem 0 1.4375rem 0.625rem"
    },
    divWrapper: {
        margin: "2.1875rem 0 0 10.125rem"
    },
    //---------------------------------taskPanel.js------------------------//
    PanelStyle: {
        float: "left",
        backgroundColor: "#2F3B52",
        height: "53rem",
        width: "47.75rem",
        borderRadius: "0.625rem",
        margin: "0.5rem",
        padding: "1rem"
    },
    SectionStyle: {
        // float: "left",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "2.1875rem",
        width: "45.75rem",
        marginBottom: "1rem"
    },
    taskCountStyle: {
        float: "right",
        height: "1.4375rem",
        width: "1.4375rem",
        color: "white",
        fontFamily: "Roboto",
        fontSize: "0.875rem",
        letterSpacing: "0",
        textAlign: "center",
        borderColor: "#9EB7C5",
        borderWidth: "0.0625rem",
        borderRadius: "0.125rem"
    },
    InputFieldStyle: {
        height: "2rem",
        width: "26rem",
        borderWidth: 0,
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        border: "none !important",
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        color: mapping["Color_Basic_Light"]
    },
    taskCardListWrapper: {
        overflowY: "scroll",
        overflowX: "hidden",
        float: "left",
        height: "43.75rem"
    },
    //-------------------------------taskCard.js---------------------------//
    cardHeaderHidden: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "80%"
    },
    CardStyle: {
        float: "left",
        backgroundColor: "#1D2739",
        height: "9.875rem",
        width: "45.875rem",
        borderRadius: "0.3125rem",
        borderLeftWidth: "1rem",
        borderLeftColor: "#00A2FF",
        marginBottom: "0.625rem",
        padding: "1.25rem 1.25rem 1.25rem 2.4375rem"
    },
    taskCardTitleStyle: {
        ...mapping["Sub Text Tag/H7-blue-tag"],
        color: "#00BEFF",
        marginBottom: "0.5rem",
        clear: "both"
    }
};

export { taskManagementStyle };
