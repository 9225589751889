const colorScheme = {
    blueColor: "rgba(0,162,255,1)",
    RatingHighColor: "rgba(255,111,27,0.9)",
    RatingMediumColor: "rgba(255,192,0,0.9)",
    RatingLowColor: "rgba(112,173,71,0.9)",
    alertInfoTitle: "rgba(255,255,255,0.4)",
    alertInfoContent: "rgba(255,255,255,0.9)",
    contentBackgroundColor: "rgba(0,0,0,0.2)",
    midDarkBackgroundColor: "rgba(33,41,56,1)",
    homeDarkBackgroundColor: "rgba(0,0,0,0.4)",
    DarkBackgroundColor: "rgba(0,0,0,0.7)",
    NewsDarkBackgroundColor: "rgba(0,0,0,0.6)",
    modelEvalPass: "rgb(197,224,180)",
    modelEvalFail: "rgb(255,0,0)",
}
export { colorScheme }