import { mapping } from "./mapping";

const businessEntityStyle = {
    headerCellStyle: mapping["headerCellStyle"],
    BlurEffectStyle: mapping["BlurEffectStyle"],
    gridStyle: {
        maxHeight: "40rem",
        height: "fit-content",
        fontSize: "0.875rem",
        marginBottom: "1.25rem"
    },
    //-------riskRegister.js----------------//
    userTableWrapper: {
        marginLeft: "5.625rem",
        marginRight: "5.625rem",
        height: "fit-content",
        width: "fit-content"
    },
    toolBarWrapper: {
        padding: "2.75rem 1rem 1.875rem 5.625rem",
        display: "flex"
    },
    moduleWrapper: {
        backgroundColor: mapping["Color_BG_05_solid"],
        height: "43.125rem"
    },
    //-----------------entityRegister-------------//
    entityToolBarWrapper: {
        marginRight: "1rem",
        display: "flex",
        justifyContent: "flex-end"
    },
    entityTableWrapper: {
        marginLeft: "15.6rem",
        marginRight: "15.6rem",
        marginTop: "3.9rem",
        height: "fit-content",
        width: "fit-content",
        backgroundColor: "rgba(0,0,0,0.4)"
    },
    tableTitlePadding: {
        paddingLeft: "1rem",
        paddingTop: "1rem"
    },

    titleStyle: {
        marginBottom: "1rem",
        ...mapping["heading/h5/lightleft"]
    },
    //--------------createEntity.js------------------//

    InputFieldStyle: {
        height: mapping["Field_Height"],
        width: "63rem",
        borderWidth: 0,
        backgroundColor: mapping["Field_BG_Color"],
        border: "none !important",
        color: mapping["Color_Basic_Light"],
        padding: "0.25rem",
        resize: "none",
        overflow: "hidden",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        borderRadius: 0,
        outline: "none"
    },
    searchDirectory: {
        ...mapping["heading/h3/lightcenter"],
        position: "relative",
        width: "100%",
        margin: "0.5rem auto",
        textAlign: "left",
        padding: "0.9375rem"
    },
    buttonPosition: {
        display: "inline-flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "1.5rem 0.9375rem 0 0.9375rem"
    },
    rightButtonPosition: {
        display: "inline-flex"
    },
    userInfoStyle: {
        ...mapping["heading/h5/lightleft"],
        marginBottom: "1rem"
    },
    fieldPadding: {
        height: "0.625rem"
    },
    titleDropDownButtonStyle: {
        width: "100%",
        height: "2.5rem",
        backgroundColor: mapping["Field_BG_Color"],
        border: "none",
        boxShadow: "none"
    },
    formCheckBoxStyle: {
        width: "100%",
        display: "inline-flex",
        justifyContent: "space-between"
    },
    riskOwnerContentStyle: {
        display: "inline-flex",
        paddingBottom: "1.25rem"
    },
    sectionTitle: {
        fontSize: "0.875rem",
        lineHeight: "normal",
        textAlign: "left",
        letterSpacing: "0.0625rem",
        textTransform: "uppercase",
        fontStyle: "normal",
        fontWeight: "bold",
        fontFamily: "Roboto",
        color: "#00BEFF",
        paddingBottom: "0.9375rem",
        paddingTop: "2.125rem"
    },
    //-------------------------------information-----------------//
    informationContainer: {
        ...mapping["heading/h3/lightcenter"],
        position: "relative",
        width: "100%",
        textAlign: "left"
    },
    informationWrapper: {
        width: "31.75rem",
        margin: "0.9375rem",
        marginTop: 0,
        marginLeft: "6.25rem",
        paddingTop: "1.5625rem"
    },
    //-----------------popup-----------------//

    creationPageStyle: {
        margin: "-20rem auto",
        textAlign: "left",
        color: "white"
    },
    creationCompleteStyle: {
        margin: "auto",
        width: "30%",
        fontSize: 18,
        backgroundColor: "#283A5D",
        boxShadow: "0px 0px 5px 0.2px black",
        padding: "0.5rem 0",
        zIndex: 4,
        position: "relative"
    },
    accountCreatedStyle: {
        marginLeft: "1rem",
        fontSize: 16,
        marginBottom: "0.5rem",
        marginTop: "0.75rem",
        fontStyle: "bold"
    },
    emailSentStyle: {
        marginLeft: "1rem",
        fontSize: 12,
        marginTop: "0.75rem",
        marginBottom: "1.75rem",
        fontStyle: "bold"
    },
    lineStyle: {
        borderWidth: "1px",
        borderColor: "#777171",
        zIndex: 4,
        position: "relative"
    },
    popupButtonPosition: { textAlign: "right", marginRight: "1rem" },
    //----------------------contentTab.js------------------//
    RiskBasicInfoStyle: {
        width: "100%",
        height: "fit-content",
        backgroundColor: "#283244",
        paddingTop: "1.875rem",
        paddingLeft: "5.625rem"
    },
    //------------------aggregationSetting.js------------//
    sectionTitleStyle: {
        ...mapping["Sub Text Tag/H7-blue-tag"],
        color: "#00BEFF",
        paddingBottom: "0.9375rem",
        paddingTop: "2.125",
        whiteSpace: "nowrap"
    },
    checkBoxTitleStyle: {
        ...mapping["forms/label/1-default"],
        ...mapping["Form_Label_Padding"],
        color: mapping["Color_Basic_Light"],
        display: "flex",
        paddingBottom: "0.9375rem"
    },
    ThresholdUnitStyle: {
        position: "absolute",
        color: "#f8f9fa",
        fontSize: "1.25rem",
        zIndex: "1",
        top: "0.4375rem",
        right: "0.375rem"
    }
};

export { businessEntityStyle };
