import React from "react";
import { mapping,  } from "../style/mapping";
import { alertManagementStyle, } from "../style/alertManagementStyle";
import {riskEditStyle} from "../style/riskEditStyle";
import {NonValidationInput} from "./Field";

class AlertDetailCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let data = dataGrouping(this.props.data.content);
        console.log(data)
        return (
            <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
                <div>
                    <div style={alertManagementStyle.sectionTitleStyle}>
                        {this.props.data.header}
                    </div>
                </div>

                {/*line one*/}
                {data.map((line)=>{
                    return(
                        <div
                            style={{ display: "inline-flex", marginBottom: "1.875rem" }}
                        >
                            {line.map((item)=>{
                                let width = item.span*10 + (item.span-1)*2.5
                                return(
                                    <div
                                        style={{ width: width+"rem", marginRight: "2.5rem" }}
                                    >
                                        <div style={{...mapping["forms/label/1-default"], whiteSpace:"nowrap"}}>
                                            {item.header}
                                        </div>
                                        {item.disabled === true &&
                                            <div style={alertManagementStyle.requestContentDisabledStyle}>
                                                {item.content}
                                            </div>
                                        }
                                        {item.disabled === false &&
                                            <NonValidationInput
                                                value={item.content}
                                                width={"100%"}
                                                onChange={(e)=>{this.props.onFieldChange(item.field, e.value)}}
                                            />
                                        }
                                    </div>
                                )
                            })}
                        </div>

                    )
                })}
            </div>
        );
    }
}

const dataGrouping = data => {
    // need to split the items into line by line according to there span
    let outputData = [];
    let totalSpan = 0;
    let tempObject = [];
    data.map(item => {
        // before push data, calculate span, if it above 5 (max of span of one line)
        totalSpan = totalSpan + item.span;
        if (totalSpan > 5) {
            outputData.push(tempObject);
            totalSpan = item.span;
            tempObject = [];
            tempObject.push(item);
        } else {
            tempObject.push(item);
        }
    });
    outputData.push(tempObject);
    return outputData;
};

export { AlertDetailCard };
