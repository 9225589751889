import { mapping } from "./mapping";

const riskManagementStyle = {
    //-----------------------------riskTable.js---------------------------------//
    headerCellStyle: mapping["headerCellStyle"],
    //-----------------------------newRiskForm.js---------------------------------//
    formBgStyle: {
        height: "fit-content",
        width: "fit-content",
        backgroundColor: mapping["Color_Extra_Half_Transparent"],
        display: "inline-flex",
        position: "relative"
    },
    ButtonStyle: {
        display: "flex",
        width: "32rem",
        justifyContent: "space-between",
        paddingBottom: "2rem"
    },
    FieldSpacingStyle: {
        paddingBottom: "2.1rem"
    },
    titleSpacingStyle: {
        paddingBottom: "1.3rem",
        paddingTop: "1.3rem"
    },
    FieldTextAreaStyle: {
        resize: "none",
        overflow: "hidden",
        height: "auto",
        width: "32rem",
        backgroundColor: mapping["Color_Field_BG_Lighter"],
        border: "none",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        color: mapping["Color_Basic_Light"],
        borderRadius: 0
    },
    RadioButtonSpace: {
        height: "2rem"
    },
    TierDropDownButtonStyle: {
        width: "5rem",
        height: "2.5rem",
        backgroundColor: mapping["Color_DropDownButton_BG_Lighter"],
        border: "none",
        boxShadow: "none"
    },
    CategoryDropDownButtonStyle: {
        width: "8.75rem",
        height: "2.5rem",
        backgroundColor: mapping["Color_DropDownButton_BG_Lighter"],
        border: "none",
        boxShadow: "none"
    },
    CategoryDropDownButtonDisableStyle: {
        width: "8.75rem",
        height: "2.5rem",
        backgroundColor: mapping["Color_DropDownButton_BG_Lighter_Disable"],
        border: "none",
        boxShadow: "none"
    },
    ParentRiskDropDownButtonStyle: {
        width: "13rem",
        height: "2.5rem",
        backgroundColor: mapping["Color_DropDownButton_BG_Lighter"],
        border: "none",
        boxShadow: "none"
    },
    DropDownListSpace: {
        width: "1.3rem"
    },
    formTitleStyle: {
        ...mapping["forms/label/1-default"],
        ...mapping["Form_Label_Padding"],
        display: "flex",
        paddingBottom: "9px",
        color: mapping["Color_Basic_Light"]
    },
    RiskCreationFormStyle: {
        position: "relative",
        left: "-50%"
    },
    RiskCreationFormWrapStyle: {
        position: "absolute",
        left: "50%",
        top: "6rem"
    },
    BlurEffectStyle: {
        filter: "blur(5px)"
    },

    riskTableWrapper: {
        marginLeft: "15.6rem",
        marginRight: "15.6rem",
        marginTop: "3.9rem",
        height: "fit-content",
        width: "fir-content",
        backgroundColor: "rgba(0,0,0,0.4)"
    },

    tableTitlePadding: {
        paddingLeft: "1rem",
        paddingTop: "1rem"
    },

    titleStyle: {
        marginBottom: "1rem",
        ...mapping["heading/h5/lightleft"]
    },
    previewImageStyle: {
        height: "17rem",
        width: "15rem",
        margin: "5rem",
        marginTop: "2rem"
    }
};

export { riskManagementStyle };
