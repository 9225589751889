import { mapping } from "./mapping";
import { sideMenuStyle } from "./sideMenuStyle";

const versionButton = {
    width: "13.5rem",
    height: "2.75rem",
    backgroundColor: "#3D4657",
    borderRadius: "1.28rem",
    display: "flex",
    position: "relative",
    zIndex: 1,
    marginBottom: "0.8rem",
    outline: "none"
};
const multilineBaseStyle = {
    padding: "0.25rem",
    resize: "none",
    overflow: "hidden",
    height: "auto",
    border: "none",
    //outline:mapping['Field_Outline']+' solid '+borderColor,
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    borderRadius: 0,
    borderWidth: 0,
    backgroundColor: mapping["Color_Form_00_Field_Solid"],
    color: mapping["Color_Basic_Light"],
    outline: "none"
};
const inputFieldStyle = {
    height: mapping["Field_Height"],
    borderWidth: "0",
    backgroundColor: mapping["Color_Form_00_Field_Solid"],
    border: "none !important",
    color: mapping["Color_Basic_Light"],
    padding: "0.25rem",
    resize: "none",
    overflow: "hidden",
    //outline:mapping['Field_Outline']+' solid '+borderColor,
    paddingLeft: mapping["Field_padding_Horizontal"],
    paddingRight: mapping["Field_padding_Horizontal"],
    borderRadius: 0,
    outline: "none"
};
const textWrapperStyle = {
    padding: "0.25rem",
    height: "fit-content",
    width: "58.5rem",
    backgroundColor: mapping["Color_Form_00_Field_Solid"],
    border: "0.0625rem solid rgba(151,151,151,0)"
};
const cardStyle = {
    width: "65.875rem",
    height: "fit-content",
    background: mapping["Color_BG_02_solid"],
    boxShadow: "0.125rem 0.125rem 0.625rem rgb(41,50,61)" // X axis, Y axis ,blur, colour
};
const riskEditStyle = {
    // -----------------  Common ------------------------//
    BlurEffectStyle: mapping["BlurEffectStyle"],
    TextStyle: {
        ...mapping["forms/input-default"],
        color: "white",
        padding: "0.25rem"
    },
    CheckBoxLabelStyle: {
        ...mapping["forms/label/1-default"],
        color: "white",
        padding: "0.3rem",
        paddingLeft: "0.8rem",
        paddingRight: "1.5rem"
    },
    contentTextStyle: {
        ...mapping["forms/input-default"],
        color: "white",
        paddingTop: "1.7rem",
        paddingBottom: "0.5rem"
    },
    followingCardPadding: {
        paddingLeft: "5rem",
        paddingTop: "2.5rem"
    },
    topCardPadding: {
        paddingLeft: "5rem",
        paddingTop: "4rem"
    },
    //-----------------Side Menu-------------------------//
    ...sideMenuStyle,
    //-------------- risk edit --------------//
    // ------------- riskEdit.js-------------//
    RiskEditSideStyle: {
        height: "fit-content",
        width: "100%",
        display: "inline-flex"
    },
    RiskEditMainStyle: {
        height: "fit-content",
        width: "100%"
    },
    topNotificationStickyBar: {
        display: "flex",
        alignItems: "center",
        padding: "1.25rem",
        position: "sticky",
        top: 0,
        backgroundColor: mapping["Color_Extra_Half_Transparent"],
        height: "3.125rem",
        color: mapping["Color_Basic_Light"],
        zIndex: 1
    },
    //-----------------------riskBasicInfo.js--------------------//

    InputFieldStyle: {
        ...inputFieldStyle,
        width: "63rem"
    },

    RiskBasicInfoStyle: {
        width: "100%",
        height: "fit-content",
        backgroundColor: "#283244",
        paddingTop: "1rem",
        paddingLeft: "5rem"
    },

    parentRiskStyle: {
        paddingTop: "0.2rem",
        ...mapping["symbols/breadcrumbs/active"],
        color: "#6C757D"
    },

    riskStatusStyle: {
        paddingTop: "1.25rem",
        marginBottom: "0.625rem"
    },

    riskInfoSectionWrapperStyle: {
        width: "100%",
        height: "fit-content",
        display: "inline-flex"
    },

    descriptionWrapperStyle: {
        width: "25rem",
        height: "100%"
    },
    riskOwnerWrapperStyle: {
        width: "15rem",
        height: "100%"
    },
    riskDelegateWrapperStyle: {
        width: "15rem",
        height: "100%"
    },
    riskTierDropDownWrapperStyle: {
        width: "100%",
        height: "100%"
    },
    riskCategoryDropDownWrapperStyle: {
        width: "100%",
        height: "100%"
    },
    riskParentDropDownWrapperStyle: {
        width: "100%",
        height: "100%"
    },
    riskRatingWrapperStyle: {
        width: "15rem",
        height: "15rem"
    },

    sectionTitleStyle: {
        ...mapping["Sub Text Tag/H7-blue-tag"],
        color: "#00BEFF",
        paddingBottom: "1.25rem",
        whiteSpace: "nowrap"
    },

    riskOwnerContentStyle: {
        display: "inline-flex",
        paddingBottom: "1.25rem"
    },

    riskRatingContentStyle: {
        transform: "scale(0.4)",
        transformOrigin: "top left",
        paddingTop: "1rem"
    },

    RiskTierWrapperStyle: {
        width: "6.875rem",
        height: "fit-content",
        marginRight: "3.94rem",
        paddingBottom: "3rem"
    },

    RiskCategoryWrapperStyle: {
        width: "13rem",
        height: "fit-content",
        marginRight: "3.94rem",
        paddingBottom: "3rem"
    },

    RiskParentWrapperStyle: {
        width: "13rem",
        height: "fit-content",
        marginRight: "3.94rem",
        paddingBottom: "3rem"
    },
    RiskTypeWrapperStyle: {
        width: "31.875rem",
        height: "fit-content",
        marginRight: "3.94rem"
    },
    riskTierDropDownButtonStyle: {
        width: "100%",
        height: "2rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    },
    buttonHeight: "1.125rem",
    buttonWidth: "3.25rem",
    TextInputStyle: { fontSize: "1.875rem", background: "none" },
    riskNumberInputWidth: "12.5rem",
    riskNameInputWidth: "71.875rem",
    riskDescriptionWidth: {
        width: "22.5rem"
    },
    riskEditPopupButtonHeight: "1.94rem",
    riskEditPopupButtonWidth: "7.94rem",
    riskEditButtonSeparatorWidth: {
        width: "1.1875rem"
    },
    riskEditSubmitButtonWrapperStyle: {
        marginLeft: "23.5rem",
        verticalAlign: "bottom",
        paddingBottom: "0.875rem",
        display: "inline-flex"
    },
    //-------------------------riskVersion.js-----------------//

    versionButtonContentStyle: {
        display: "inline-flex",
        width: "fit-content",
        margin: "auto",
        marginLeft: "0.2rem",
        height: "fit-content"
    },
    versionButtonLeftIconStyle: {
        backgroundColor: mapping["Color_Basic_Secondary"],
        borderRadius: "50%",
        width: "2.375rem",
        height: "2.375rem"
    },
    versionButtonNameStyle: {
        textAlign: "left",
        ...mapping["Font_Version_Name"],
        lineHeight: "1.0625rem"
    },
    versionButtonDateStyle: {
        ...mapping["Font_Version_Date"],
        width: "50%",
        textAlign: "left",
        display: "inline-flex"
    },
    versionButtonStatusStyle: {
        ...mapping["Font_Version_Status"],
        width: "50%",
        textAlign: "left",
        display: "inline-flex"
    },
    versionDotLineStyle: {
        top: "4rem",
        marginLeft: "1.41rem",
        height: "16rem",
        display: "inline-block",
        borderStyle: "dashed",
        borderLeftWidth: "0.0625rem",
        zIndex: "0",
        position: "absolute",
        borderColor: "rgba(181,198,209,0.5)"
    },
    versionButtonViewMoreWrapperStyle: {
        marginLeft: "1.125rem",
        fontSize: 0,
        position: "absolute",
        top: "20rem"
    },
    versionDotCircleStyle: {
        height: "0.625rem",
        width: "0.625rem",
        backgroundColor: "#283244",
        borderRadius: "50%",
        border: "solid",
        borderColor: "#6C7A88",
        display: "inline-block"
    },
    riskVersionContentWrapperStyle: {
        marginTop: "1.25rem",
        width: "16rem",
        height: "17.5rem",
        fontSize: 0,
        position: "relative",
        overflowY: "scroll"
    },
    riskVersionWrapperStyle: {
        width: "fit-content",
        height: "20rem",
        fontSize: 0,
        position: "relative"
    },
    versionButton: versionButton,
    versionButtonHover: {
        ...versionButton,
        border: "0.125rem solid #00A2FF"
    },
    versionCreatorContentWrapper: {
        width: "8.1875rem",
        paddingLeft: "0.375rem",
        fontSize: 0
    },
    versionCreatedDateContentWrapper: {
        width: "100%",
        height: "0.8125rem",
        marginTop: "0.375rem"
    },
    mainCardStyle: {
        height: "fit-content",
        overflow: "auto",
        width: "68.8125rem",
        backgroundColor: mapping["Color_BG_04_solid"],
        borderRadius: "0.625rem",
        boxShadow: "0 0 0.9375rem rgba(0,0,0,0.3)" // X axis, Y axis ,blur, colour
    },
    moreIconWrapper: {
        marginRight: "0.375rem",
        marginLeft: "auto",
        display: "flex",
        alignItems: "center"
    },
    // --------------------------riskSummary.js---------------//

    RiskSummaryStyle: {
        width: "100%",
        height: "fit-content",
        backgroundColor: mapping["Color_BG_05_solid"]
    },
    blockPaddingStyle: {
        paddingLeft: "5rem",
        paddingTop: "3rem"
    },
    bottomPaddingStyle: {
        height: "5rem"
    },
    //-------------------------summary.js----------------------//
    summaryStyle: {
        paddingLeft: "5rem",
        paddingTop: "4rem"
    },
    detailInfoBox: {
        margin: "1.43rem",
        height: "27rem",
        width: "65.875rem",
        backgroundColor: mapping["Color_BG_02_solid"],
        boxShadow: "0.125rem 0.125rem 0.625rem rgb(41,50,61)" // X axis, Y axis ,blur, colour
    },
    BoxStyle: {
        marginTop: "1.25rem",
        width: "20.96rem",
        height: "11.438rem",
        background: mapping["Color_BG_02_solid"],
        boxShadow: "0.125rem 0.125rem 0.625rem rgb(41,50,61)" // X axis, Y axis ,blur, colour
    },
    DetailBoxContentWrapperStyle: {
        paddingLeft: "1rem",
        paddingRight: "2.5rem",
        width: "100%"
    },
    DetailBoxContentBackgroundStyle: {
        display: "inline-flex",
        width: "100%",
        padding: "1rem 1.5rem 1rem 1.5rem",
        justifyContent: "space-between",
        backgroundColor: "#3E4E6C",
        height: "fit-content"
    },
    DetailBoxContentStyle: {
        ...mapping["Font_Summary_Detail_Row"],
        height: "fit-content",
        margin: "auto 0 auto 0"
    },
    DetailControlCSAIconStyle: {
        margin: "auto 0 auto 0",
        backgroundColor: mapping["Color_Basic_Primary"],
        borderRadius: "0.25rem",
        width: "2rem",
        height: "2rem"
    },
    summaryDetailBoxWrapper: {
        height: "23.125rem",
        overflowY: "scroll"
    },

    //-----------------------riskRating.js----------------------//
    RiskRatingStyle: {
        paddingLeft: "5rem",
        paddingTop: "2.5rem"
    },
    leftBox: {
        marginLeft: "1.25rem",
        marginTop: "1.25rem",
        clear: "both",
        float: "left",
        height: "4.375rem"
    },
    leftBoxHeader: {
        height: "2.5rem",
        color: "rgb(53, 66, 92)"
    },
    leftSubBox: {
        marginTop: "0.625rem",
        width: "35.125rem",
        height: "3.8125rem",
        background: "rgb(53, 66, 92)",
        color: "rgb(53, 66, 92)",
        boxShadow: "0.125rem 0.125rem 0.625rem rgb(41,50,61)" // X axis, Y axis ,blur, colour
    },
    riskRatingDropDownButtonStyle: {
        marginLeft: "0.3125rem",
        marginRight: "0.3125rem",
        width: "8rem",
        height: "2rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    },
    riskRatingMatrixStyle: {
        margin: "1.5rem",
        transform: "scale(0.97)",
        transformOrigin: "top left"
    },
    RiskRatingContentStyle: {
        height: "39rem",
        width: "68.8125rem",
        backgroundColor: mapping["Color_BG_04_solid"],
        borderRadius: "0.625rem",
        boxShadow: "0 0 0.3125rem rgba(0,0,0,0.3)" // X axis, Y axis ,blur, colour
    },
    rightBox: {
        marginRight: "1.625rem",
        marginTop: "2rem",
        float: "right",
        width: "29.375rem",
        height: "32rem",
        background: mapping["Color_BG_02_solid"],
        boxShadow: "0.125rem 0.125rem 0.625rem rgb(41,50,61)" // X axis, Y axis ,blur, colour
    },
    leftBoxViewWrapperStyle: {
        paddingTop: "1.2rem",
        display: "inline-flex"
    },
    leftBoxWrapperStyle: {
        paddingTop: "0.8rem",
        display: "inline-flex"
    },
    //  --- Top ribbon header text
    leftBoxHeaderText: {
        width: "10rem",
        paddingLeft: "1rem",
        paddingTop: "1.4rem",
        float: "left",
        ...mapping["Sub Text Tag/H7-blue-tag"],
        color: "rgb(4, 176, 235)"
    },
    leftBoxHeaderTextShort: {
        width: "8rem",
        paddingLeft: "1rem",
        paddingTop: "1.4rem",
        float: "left",
        ...mapping["Sub Text Tag/H7-blue-tag"],
        color: "rgb(4, 176, 235)"
    },
    leftSubBoxText: {
        width: "8.5rem",
        paddingLeft: "1.125rem",
        float: "left",
        ...mapping["forms/input-default"],
        color: "white"
    },
    pillViewStyle: {
        float: "left",
        marginLeft: "0.5rem"
    },
    pillStyle: {
        float: "left",
        marginTop: "0.4rem",
        marginLeft: "0.5rem"
    },
    riskRating4Color: mapping["Color_Platform_RiskHigh"] + "66",
    riskRating3Color: mapping["Color_Platform_RiskMed"] + "66",
    riskRating2Color: "#5293e366",
    riskRating1Color: mapping["Color_Platform_RiskLow"] + "66",

    //-------------------------- riskAppetite.js-------------------------//

    RiskAppetiteStyle: {
        paddingLeft: "5rem",
        paddingTop: "2.5rem"
    },
    TextWrapperStyle: {
        ...textWrapperStyle,
        minHeight: "2rem",
        width: "51.5rem"
    },
    ShortTextWrapperStyle: {
        minHeight: "2rem",
        padding: "0.25rem",
        height: "fit-content",
        width: "29.625rem",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        border: "0.0625rem solid rgba(151,151,151,0)",
        marginRight: "1.5rem"
    },

    cardStyle: cardStyle,
    cardBGStyle: {
        position: "relative",
        width: "65.875rem",
        height: "fit-content",
        background: mapping["Color_BG_02_solid"],
        boxShadow: "0.125rem 0.125rem 0.625rem rgb(41,50,61)" // X axis, Y axis ,blur, colour
    },
    MultilineInputFieldStyle: {
        ...multilineBaseStyle,
        width: "51.5rem"
    },
    riskAppetiteStatementWrapperStyle: {
        padding: "1.25rem",
        display: "inline-flex"
    },
    riskAppetiteLevelWrapperStyle: {
        padding: "1.25rem",
        paddingRight: 0,
        display: "inline-flex",
        width: "100%"
        //justifyContent: "space-between",
    },
    //-----------------------------driverConsequences.js-----------------//
    TextWrapperWithIconStyle: {
        padding: "0.25rem",
        height: "fit-content",
        width: "56rem",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        border: "0.0625rem solid rgba(151,151,151,0)"
    },
    fullBoxStyle: {
        marginTop: "1rem",
        width: "65.875rem",
        height: "fit-content",
        background: mapping["Color_BG_02_solid"],
        boxShadow: "0.125rem 0.125rem 0.625rem rgb(41,50,61)" // X axis, Y axis ,blur, colour
    },
    InputFieldWithIconStyle: {
        height: mapping["Field_Height"],
        width: "61rem",
        borderWidth: "0",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        border: "none !important",
        color: mapping["Color_Basic_Light"],
        padding: "0.25rem",
        resize: "none",
        overflow: "hidden",
        //outline:mapping['Field_Outline']+' solid '+borderColor,
        paddingLeft: mapping["Field_padding_Horizontal"],
        paddingRight: mapping["Field_padding_Horizontal"],
        borderRadius: 0,
        outline: "none"
    },

    //-------------------------------------------exsitingControl.js----------------------//
    controlOwnerWrapper: {
        paddingRight: "2rem",
        width: "fit-content"
    },
    controlTextWrapperStyle: {
        padding: "0.25rem",
        height: "fit-content",
        width: "58.5rem",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        border: "0.0625rem solid rgba(151,151,151,0)"
    },

    controlCardStyle: cardStyle,
    controlInputFieldStyle: {
        ...inputFieldStyle,
        width: "58.5rem"
    },
    controlMultilineInputFieldStyle: {
        ...multilineBaseStyle,
        width: "58.5rem"
    },
    ControlSourceFont: {
        width: "15.625rem",
        fontSize: "1rem",
        lineHeight: "1.125rem",
        textAlign: "start",
        color: "white",
        fontStyle: "normal",
        fontWeight: "400",
        fontFamily: "Roboto",
        padding: "0.3rem 1.5rem 0.3rem 0rem",
        display: "flex"
    },
    controlDeleteIcon: {
        float: "right",
        margin: "0.5rem"
    },
    // -----------------------------------KeyRiskIndicator.js-------------------//

    KRITextWrapperStyle: {
        ...textWrapperStyle,
        width: "58.5rem"
    },
    ThresholdDangerInputFieldStyle: {
        height: "2rem",
        width: "5rem",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        fontSize: "0.75rem",
        padding: "0.25rem",
        textAlign: "center",
        color: mapping["Color_Basic_Light"],
        border: "0.125rem solid #FF0000",
        borderRadius: "0.25rem",
        boxShadow: "none"
    },
    ThresholdSymbolStyle: {
        position: "absolute",
        color: mapping["Color_Basic_Light"],
        fontSize: "0.75rem",
        zIndex: 1,
        top: "0.375rem",
        left: "0.375rem"
    },
    ThresholdUnitStyle: {
        position: "absolute",
        color: mapping["Color_Basic_Light"],
        fontSize: "0.75rem",
        zIndex: 1,
        top: "0.4375rem",
        right: "0.375rem"
    },
    thresholdMiddleStyle: {
        height: "2rem",
        width: "8rem",
        backgroundColor: mapping["Color_KRI_Middle_Background"],
        fontSize: "0.75rem",
        padding: "0.25rem",
        paddingTop: "0.375rem",
        textAlign: "center",
        color: "black"
    },
    ThresholdMidInputFieldStyle: {
        height: "2rem",
        width: "5rem",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        color: mapping["Color_Basic_Light"],
        fontSize: "0.75rem",
        padding: "0.25rem",
        paddingTop: "0.375rem",
        textAlign: "center",
        border: "0.125rem solid " + mapping["Color_KRI_Middle_Background"]
    },
    ThresholdSafeInputFieldStyle: {
        height: "2rem",
        width: "5rem",
        backgroundColor: mapping["Color_Form_00_Field_Solid"],
        fontSize: "0.75rem",
        padding: "0.25rem",
        textAlign: "center",
        color: mapping["Color_Basic_Light"],
        border: "0.125rem solid #3CA785",
        borderRadius: "0.25rem",
        boxShadow: "none"
    },
    KRICardStyle: {
        ...cardStyle,
        position: "relative"
    },
    riskThresholdTypeDropDownButtonStyle: {
        width: "3.5rem",
        height: "2rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    },
    KRIInputFieldStyle: {
        ...inputFieldStyle,
        width: "58.5rem"
    },
    KRIValueEntryButtonHeight: "1.3125rem",
    KRIValueEntryButtonWidth: "6.25rem",

    //--------------------------actionPlan.js-----------------------------//

    APTextWrapperStyle: {
        ...textWrapperStyle,
        width: "58.5rem"
    },

    APInputFieldStyle: {
        ...inputFieldStyle,
        width: "58.5rem"
    },
    statusDueDate: {
        ...mapping["paragraph/tiny/lightleft"],
        paddingTop: "0.4rem",
        paddingLeft: "0.5rem"
    },
    completeCheckBoxWrapper: {
        height: 0,
        display: "inline-flex",
        float: "right",
        margin: "0.5rem"
    },
    //-------------------------------- riskMatrixExplanation.js---------------------//

    RiskCreationFormStyle: {
        position: "relative",
        left: "-50%"
    },
    RiskCreationFormWrapStyle: {
        position: "absolute",
        left: "50%",
        top: "100rem"
    },
    formBgStyle: {
        height: "fit-content",
        width: "fit-content",
        backgroundColor: mapping["Color_Extra_Half_Transparent"],
        display: "inline-flex",
        position: "relative"
    },
    FormTitleStyle: {
        ...mapping["heading/h5/lightleft"],
        marginBottom: "1rem"
    },
    tableStyle: {
        height: "fit-content",
        width: "62.5rem"
    },
    popupContentMargin: {
        margin: "6.25rem"
    },
    closeButton: {
        display: "inline-flex",
        marginLeft: "62.5rem"
    },

    //------------------------------riskMap.js--------------------------------//
    riskMapStyle: {
        marginTop: "3.75rem",
        height: "11rem",
        width: "68.75rem",
        backgroundColor: "#2F3B52",
        borderRadius: "0.625rem",
        boxShadow: "0 0.125rem 0.625rem 0 rgba(0,0,0,0.3)",
        marginBottom: "3rem"
    },
    riskMapTitleStyle: {
        width: "100%",
        height: "2.5rem",
        padding: "1.125rem",
        ...mapping["heading/h5/lightleft"]
    },
    riskMapContentStyle: {
        paddingTop: "1.0625rem",
        paddingLeft: "1.4375rem",
        display: "inline-flex"
    },
    riskFromStyle: {
        width: "29.375rem",
        marginRight: "1.5625rem",
        marginLeft: "1.0625rem"
    },
    mapInputFieldStyle: inputFieldStyle,
    mapSourceTextStyle: {
        fontSize: "1.25rem",
        marginTop: "0.8125rem"
    },

    // -------------------------------KRIValue.js-----------------------------------//

    KRIValueInputFieldStyle: inputFieldStyle,
    KRIFormBgStyle: {
        height: "fit-content",
        width: "36.1875rem",
        padding: "1.4375rem 1.125rem 2.6875rem 1.125rem",
        backgroundColor: mapping["Color_Extra_Half_Transparent"],
        //display: "inline-flex",
        position: "relative"
    },
    KRIValueCreationFormWrapStyle: {
        position: "absolute",
        left: "50%",
        top: "13.3rem"
    },
    KRIDropDownButtonStyle: {
        width: "17rem",
        height: "2rem",
        backgroundColor: mapping["Color_Basic_Secondary"],
        border: "none",
        boxShadow: "none"
    },
    KRIValueButtonHeight: "2.4375rem",
    KRIValueButtonWidth: "4.9375rem"
};

export { riskEditStyle };
